import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {AccountProvider} from '../../../../providers/account/account';
import {MemberProvider} from '../../../../providers/company/member/member';
import {ContaBancariaDTO} from '../../../../model/dto/ContaBancariaDTO';
import {PessoaFiscalDTO} from '../../../../model/dto/PessoaFiscalDTO';
import {HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';
import {ExcelExportProperties, GridComponent, SelectionService} from '@syncfusion/ej2-angular-grids';
import {ClickEventArgs} from '@syncfusion/ej2-navigations';
import {ErrorUtil} from '../../../../util/error';
import {Snackbar} from '../../../../util/snackbar';
import {DateUtil} from 'src/util/dateUtil';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import {ReportsProvider} from 'src/providers/reports/ReportsProvider';
import {ImovelDTO} from "../../../../model/dto/ImovelDTO";
import {PropertyProvider} from "../../../../providers/company/property/property";
import {BankAccountProvider} from "../../../../providers/company/bank_account/bank_account";
import {ReportsUtil} from "../../../../util/Reports";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {ModalExportComponent} from "../modal-export/modal-export.component";
import {MembersCharts} from "./charts";


@Component({
  selector: 'app-analise-participante',
  templateUrl: './analise-participante.component.html',
  styleUrls: ['./analise-participante.component.scss'],
  providers: [SelectionService,
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
export class AnaliseParticipanteComponent implements OnInit {

  @ViewChild('grid', {static: false}) public grid: GridComponent;

  consultando: boolean;
  anual = true;
  personalizado = false;
  year: number;
  years: any[];
  deducao: string;
  months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];

  contas: ContaBancariaDTO[];
  socios: PessoaFiscalDTO[];
  imoveis: ImovelDTO[];
  members = new FormControl();
  bankAccount = new FormControl();
  property = new FormControl();

  month: any;
  toolbarOptions: any[];
  dataSourceMain: Array<any> = [];
  despesaAttributes: Object;
  receitaAttributes: Object;

  public dateRange = new ConsultDate();
  public changed: boolean = false;

  public links = ['Receita', 'Despesa'];
  // @ts-ignore
  public activeLink: 'Receita' | 'Despesa' = this.links[0];

  constructor(public accountProvider: AccountProvider,
              public errorUtil: ErrorUtil,
              public modalService: NgbModal,
              private propertyProvider: PropertyProvider,
              private bancoProvider: BankAccountProvider,
              public snackBar: Snackbar,
              private reportsProvider: ReportsProvider,
              public memberProvider: MemberProvider,
              private dateUtil: DateUtil,
              public charts: MembersCharts,
              public reportsUtil: ReportsUtil,
              private _adapter: DateAdapter<any>) {
  }

  ngOnInit() {
    this.defaultConfiguration();
    this.initializeData();
    this.tableConfiguration();
    this.charts.start();
  }

  defaultConfiguration() {
    this.month = moment(localStorage.getItem('workDate')).locale('pt-BR').format('MMM').toUpperCase();
    this.year = +moment.tz(localStorage.getItem('workDate'), 'America/Sao_Paulo').format('YYYY');
    this.years = getYears(+moment().format('YYYY') - 20);
    const workDate = this.dateUtil.removeTimeZone(localStorage.getItem('workDate'));
    this.dateRange.startDate = moment.tz(workDate, 'America/Sao_Paulo').startOf('month').format('YYYY-MM-DD');
    this.dateRange.endDate = moment.tz(workDate, 'America/Sao_Paulo').endOf('year').format('YYYY-MM-DD');
  }

  tableConfiguration() {
    this.toolbarOptions = [
      {text: 'Exportar', tooltipText: 'Exportar', prefixIcon: 'e-pdfexport', id: 'export'},
      'ColumnChooser'
    ];
    this._adapter.setLocale('pt-BR');
    this.despesaAttributes = {style: 'color: red;'};
    this.receitaAttributes = {style: 'color: green;'};
  }

  initializeData() {
    this.deducao = 'TODOS';
    this.consultando = true;
    this.dataSourceMain = [];

    this.memberProvider.getAllMembers().then((members: PessoaFiscalDTO[]) => {
      this.socios = members;
      this.bancoProvider.getAllBanksAccounts().then((accounts: ContaBancariaDTO[]) => {
        this.contas = accounts;
        this.contas.push({
          id: null,
          description: 'Sem conta',
          bankId: null,
          code: null,
          countryId: null,
          agencyWithoutDV: null,
          accountNumberWithDV: null,
          bankDTORespComp: null,
          countryDTORespComp: null,
          default: false
        });
        this.propertyProvider.getAllProperty().then((properties: ImovelDTO[]) => {
          this.imoveis = properties;
          this.consultando = false;
          this.initialSelects();
        }).catch((result: HttpErrorResponse) => {
          this.errorUtil.checkErrorStatus(result, result.status, result.error, 'relatorios-livroCaixa');
          this.consultando = false;
          console.error(result);
        });
      });
    });
  }

  tabsTypeFilter() {
    this.consultando = true;
    this.dataSourceMain = [];

    let members = this.socios.map((value) => value.cpfCNPJ);
    if (this.members.value.length !== 0) {
      members = this.members.value.map((value) => value.cpfCNPJ);
    }

    let property = this.imoveis.map((value) => value.propertyCode);
    if (this.property.value.length !== 0) {
      property = this.property.value.map((value) => value.propertyCode);
    }

    let account = this.contas.map((value) => value.id);
    if (this.bankAccount.value.length !== 0) {
      account = this.bankAccount.value.map((value) => value.id);
    }

    const month = this.getActiveMonth();
    const date = this.year + '-' + month + '-' + '01';
    // monthy
    let startDate = moment.tz(date, 'America/Sao_Paulo').startOf('month').format('YYYY-MM-DD');
    let endDate = moment.tz(date, 'America/Sao_Paulo').endOf('month').format('YYYY-MM-DD');
    if (this.personalizado) { // custom
      startDate = this.dateRange.startDate;
      endDate = this.dateRange.endDate;
    } else if (this.anual) { // anual
      startDate = this.year + '-01-01';
      endDate = this.year + '-12-31';
    }

    let realeseType = 0;
    if (this.deducao == 'DEDUTIVEIS') {
      realeseType = 1;
    } else if (this.deducao == 'NAODEDUTIVEIS') {
      realeseType = 2;
    }

    let params = {
      cpfCnpjs: members,
      postingReleaseType: realeseType,
      propertyCodes: property,
      bankAccountIds: account
    }

    this.generateReport(params, startDate, endDate);
  }

  generateReport(params, initalDate, finalDate) {
    this.reportsProvider.getReportMembers(initalDate, finalDate, params)
      .then((result: any) => {
        this.consultando = false;
        this.dataSourceMain = result;
        this.changed = false;
        this.charts.aux = result;
        this.charts.generate();
      }).catch((result: HttpErrorResponse) => {
      this.errorUtil.checkErrorStatus(result, result.status, result.error, 'relatorios-analiseParticipant');
      this.changed = false;
      this.consultando = false;
      console.error(result);
    });
  }

  chosenYearHandler(ano: any) {
    this.year = ano;
  }

  updateChanged() {
    this.changed = true;
  }

  limpaMembers(bool) {
    if (bool) {
      this.members.setValue(this.socios);
    } else {
      this.members.setValue([])
    }
  }

  limpaImoveis(bool) {
    if (bool) {
      this.property.setValue(this.imoveis)
    } else {
      this.property.setValue([])
    }
  }

  limpaConta(bool) {
    if (bool) {
      this.bankAccount.setValue(this.contas)
    } else {
      this.bankAccount.setValue([])
    }
  }

  initialSelects() {
    this.members.setValue([])
    this.property.setValue([])
    this.bankAccount.setValue([])
  }

  getActiveMonth() {
    switch (this.month) {
      case 'JAN':
        return '01';
      case 'FEV':
        return '02';
      case 'MAR':
        return '03';
      case 'ABR':
        return '04';
      case 'MAI':
        return '05';
      case 'JUN':
        return '06';
      case 'JUL':
        return '07';
      case 'AGO':
        return '08';
      case 'SET':
        return '09';
      case 'OUT':
        return '10';
      case 'NOV':
        return '11';
      case 'DEZ':
        return '12';
      default:
        return '01'
    }
  }

  toolbarClick(args: ClickEventArgs): void {
    if (args.item.id === 'export') {
      let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : '',
      };
      const modalRef = this.modalService.open(ModalExportComponent, modalOptions);
      modalRef.componentInstance.texto = "as despesas ou receitas de cada participante";
      modalRef.componentInstance.relatorio = 'analise-participante';
      modalRef.result.then((result: any) => {
        if (result.type === 'pdf') {
          this.exportPDF(result.img);
        } else if (result.type === 'excel') {
          this.exportExcel();
        }
      });
    }
  }

  exportPDF(img){
    const date = moment().format('DD/MM/YY HH:mm').toString();

    let deducao: string;
    if (this.deducao === 'TODOS')
      deducao = 'Todos os valores';
    else if (this.deducao === 'DEDUTIVEIS')
      deducao = 'Dedutíveis';
    else
      deducao = 'Não dedutíveis';

    let properties = 'Todos os imóveis';
    if (this.property.value != null) {
      if (!(this.property.value.length == this.imoveis.length || this.property.value.length == 0)) {
        let tmp = [];
        this.property.value.forEach((value) => {
          tmp.push(value.propertyCode);
        });
        properties = (tmp.length > 1) ? tmp.join(', ') : tmp[0];
      }
    }

    let accounts = 'Todas as contas';
    if (this.bankAccount.value != null) {
      if (!(this.bankAccount.value.length == this.contas.length || this.bankAccount.value.length == 0)) {
        let tmp = [];
        this.bankAccount.value.forEach((value) => {
          tmp.push(value.description);
        });
        accounts = (tmp.length > 1) ? tmp.join(', ') : tmp[0];
      }
    }

    let members = 'Todos os produtores';
    if (this.members.value != null) {
      if (!(this.members.value.length == this.socios.length || this.members.value.length == 0)) {
        let tmp = [];
        this.members.value.forEach((value) => {
          tmp.push(value.name);
        });
        if (tmp.length > 1) {
          members = tmp.join(', ');
        } else if (tmp.length == 1)  {
          members = `${this.members.value[0].name} (${this.members.value[0].cpfCNPJ})`;
        }
      }
    }

    let period;
    if (this.anual)
      period = this.year;
    else if (this.personalizado)
      period = moment(this.dateRange.startDate).format('DD/MM/YY') + ' - ' + moment(this.dateRange.endDate).format('DD/MM/YY');
    else
      period = this.month + ' - ' + this.year;

    let headers = [
      {header: 'Participante', dataKey: 'name'},
      {header: 'CNPJ', dataKey: 'cpfCnpj'},
      {header: 'Receita', dataKey: 'incoming'},
      {header: 'Despesa', dataKey: 'outlay'},
      {header: 'Saldo', dataKey: 'balance'},
    ];

    let tmp = [];
    this.dataSourceMain.forEach((value: any) => {
      tmp.push({
        name: value.name,
        cpfCnpj: value.cpfCnpj,
        incoming: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value.incoming),
        outlay: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value.outlay),
        balance: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value.balance),
      });
    });

    let colunas = [
      {dataKey: 'incoming', minCellWidth: 2.5, halign: 'center'},
      {dataKey: 'outlay',   minCellWidth: 2.5, halign: 'center'},
      {dataKey: 'balance',  minCellWidth: 2.5, halign: 'center'},
    ];

    headers = headers.filter((value) => {
      return this.grid.getVisibleColumns().find((column) => {
        return column.field === value.dataKey;
      });
    })

    this.reportsUtil.exportPDF({
      doc: {
        arquivo: `Analise participante - ${period}`,
        orientacao: 'Landscape',
      },
      cabecalho: {
        titulo: `Análise por Participante`,
        data: date,
        periodo: period,
        parametros: {
          "Dedução": deducao,
          "Imóveis": properties,
          "Produtores": members,
          "Contas": accounts,
        },
        image: img,
      },
      tabela: {
        dados: tmp,
        colunas: headers,
        estiloColunas: colunas,
      },
    });
  }

  exportExcel(){
    const exportProperties: ExcelExportProperties = {
      includeHiddenColumn: true,
      fileName: 'analiseparticipante' + this.year + '.xlsx',
    };

    for (const cols of this.grid.columns) {
      if ((cols as any).field === 'name') {
        (cols as any).width = 150;
      }
      if ((cols as any).field === 'cpfCnpj') {
        (cols as any).width = 100;
      }
      if ((cols as any).field === 'incoming') {
        (cols as any).width = 130;
      }
      if ((cols as any).field === 'outlay') {
        (cols as any).width = 130;
      }
      if ((cols as any).field === 'balance') {
        (cols as any).width = 130;
      }
      if ((cols as any).field === 'type') {
        (cols as any).visible = false;
      }
    }

    this.grid.excelExport(exportProperties);
  }

  queryCell(args) {
    if (args.column.field === 'balance' ) {
      // tslint:disable-next-line: no-string-literal
      if (args.data.balance < 0) {
        args.cell.setAttribute('style', 'color: red');
      } else {
        args.cell.setAttribute('style', 'color: green');
      }
    }
    if (args.column.field === 'incoming') {
      if (args.data.incoming != 0) {
        args.cell.setAttribute('style', 'color: green');
      }
    }

    if (args.column.field === 'outlay') {
      if (args.data.outlay != 0) {
        args.cell.setAttribute('style', 'color: red');
      }
    }
  }

  excelQueryCellInfo(args: any): void {
    let textColor = '#000000';
    if (args.column.field == 'incoming') {
      if (args.value != 0) {
        textColor = '#27c24c';
      }
      args.value = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(args.value);
    }
    if (args.column.field == 'outlay') {
      if (args.value != 0) {
        textColor = '#f05050';
      }
      args.value = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(args.value);
    }
    if (args.column.field === 'balance') {
      if (args.value >= 0) {
        textColor = '#27c24c';
      } else if (args.value < 0) {
        textColor = '#f05050';
      }
      args.value = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(args.value);
    }
    args.style = {textBrushColor: textColor};
  }

  changePeriodStart(data) {
    if (data.value != null && this.dateRange.startDate != null && this.dateRange.endDate != null) {
      // tslint:disable-next-line: deprecation
      this.dateRange.startDate = moment.tz(data.value, 'America/Sao_Paulo').format('YYYY-MM-DD');
    }
  }

  changePeriodEnd(data) {
    if (data.value != null && this.dateRange.startDate != null && this.dateRange.endDate != null) {
      // tslint:disable-next-line: deprecation
      this.dateRange.endDate = moment.tz(data.value, 'America/Sao_Paulo').format('YYYY-MM-DD');
    }
  }

  tabs(link: 'Receita' | 'Despesa') {
    this.charts.changeTab(link);
  }
}

function getYears(startYear) {
  const currentYear = new Date().getFullYear() + 1;
  // tslint:disable-next-line: prefer-const
  let years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}

class ConsultDate {
  endDate: any;
  startDate: any;
}
