import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ValueValidator} from "../../../../util/customValidators/valueValidator";
import * as moment from "moment";
import {map, startWith, takeUntil} from "rxjs/operators";
import {NgbActiveModal, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {PropertyProvider} from "../../../../providers/company/property/property";
import {ParticipantProvider} from "../../../../providers/participant/participant";
import {BankAccountProvider} from "../../../../providers/company/bank_account/bank_account";
import {Snackbar} from "../../../../util/snackbar";
import {LivroCaixa} from "../../../../util/livroCaixa";
import {ImovelDTO} from "../../../../model/dto/ImovelDTO";
import {ContaBancariaDTO} from "../../../../model/dto/ContaBancariaDTO";
import {ParticipantDTOResponse} from "../../../../model/dto/ParticipantDTOResponse";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {ConsultaDfe} from "../../../../model/ConsultaDfe";
import {Duplicatas} from "../../../../model/Duplicatas";
import {AccountProvider} from "../../../../providers/account/account";
import {
  CadastroContaBancariaComponent
} from "../../minhasPropriedades/cadastro-conta-bancaria/cadastro-conta-bancaria.component";
import {CadastroImovelComponent} from "../../minhasPropriedades/cadastro-imovel/cadastro-imovel.component";
import {
  CadastroPlanoDeContasComponent
} from "../../cadastros/plano-de-contas/cadastro-plano-de-contas/cadastro-plano-de-contas.component";
import {AccountPlanDTOResponse} from "../../../../model/dto/AccountPlanDTOResponse";
import {GrouperDTOResponse} from "../../../../model/dto/GrouperDTOResponse";
import {ReleaseType} from "../../../../model/enum/ReleaseType";
import {HttpErrorResponse} from "@angular/common/http";
import {PostingProvider} from "../../../../providers/bookkeeping/posting/posting";
import {ErrorUtil} from "../../../../util/error";
import {PagamentoPlanejadoComponent} from "./pagamento-planejado/pagamento-planejado.component";
import {DataTrabalhoDialog} from "../data-trabalho-dialog/data-trabalho-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {DocumentProvider} from "../../../../providers/bookkeeping/document/document";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {HistoricDTOResponse} from "../../../../model/dto/HistoricDTOResponse";
import {HistoricProvider} from "../../../../providers/bookkeeping/historic/historic";
import {CadastroHistoricoComponent} from "../../cadastros/historicos/cadastro-historico/cadastro-historico.component";
import {DateUtil} from "../../../../util/dateUtil";
import {ConvertObjectPipe} from "../../../../util/pipes/ConvertObjectPipe";
import {Dialog} from "../../../../model/Dialog";
import {BasicAlertComponent} from "../../../shared/alert/basic-alert/basic-alert.component";
import {DateLockProvider} from 'src/providers/bookkeeping/date-lock/date-lock';
import Swal from 'sweetalert2'
import {DateLock} from 'src/model/dto/bookkeping/DateLock';
import {CheckPermission} from 'src/service/checkPermission';
import {GrouperProvider} from 'src/providers/grouper/grouper';
import {ReceiptProvider} from 'src/providers/receipt/receipt';
import {MatAutocompleteSelectedEvent, MatChipInputEvent} from '@angular/material';
import {
  AdicionarArquivosComponent
} from '../../consultor/enviar-arquivos/adicionar-arquivos/adicionar-arquivos.component';
import {
  CadastroAgrupadoresComponent
} from '../../cadastros/agrupadores/cadastro-agrupadores/cadastro-agrupadores.component';
import {DfeProvider} from '../../../../providers/dfe/dfe';
import {ConsultaPdfXml} from '../../../../providers/dfe/consultaPdfXml';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {ContaMovimentoIndividualComponent} from './conta-movimento-individual/conta-movimento-individual.component';
import {ContaIndividualComponent} from './conta-individual/conta-individual.component';
import {ImovelIndividualComponent} from './imovel-individual/imovel-individual.component';
import {historicoUtil} from 'src/util/historico';
import {HistoricoIndividualComponent} from './historico-individual/historico-individual.component';

@Component({
  selector: 'app-cadastrar-lancamento-dup',
  templateUrl: './cadastrar-lancamento-dup.component.html',
  styleUrls: ['./cadastrar-lancamento-dup.component.scss']
})

export class CadastrarLancamentoDupComponent implements OnInit {

  @Input() public documentosExistentes: ConsultaDfe[];
  @Input() public categoriasCache: AccountPlanDTOResponse[];
  @Input() public imoveisCache: ImovelDTO[];
  @Input() public contasCache: ContaBancariaDTO[];
  @Input() public historicosCache: HistoricDTOResponse[];
  @Input() public participantesCache: ParticipantDTOResponse[];

  @ViewChild('grouperInput', {static: false}) grouperInput: ElementRef<HTMLInputElement>;
  @ViewChild('receiptInput', {static: false}) receiptInput: ElementRef<HTMLInputElement>;
  @ViewChild('historicInput', {static: false}) historicInput: ElementRef<HTMLInputElement>;


  public lancamentoForm: FormGroup;

  public imoveis: ImovelDTO[];
  public contas: ContaBancariaDTO[];
  public categorias: AccountPlanDTOResponse[];
  public historicos: HistoricDTOResponse[];
  public participantes: ParticipantDTOResponse[];

  enviado: boolean = false;
  carregando: boolean = false;
  changeMade: boolean = false;
  displayedColumns: string[] = ['select', 'id', 'data', 'valor', 'button'];
  selection = new SelectionModel<any>(true, []);
  valorTotal: number;
  descontoProporcionalAtivo: boolean = false;
  valorProporcional: string;
  saleDfe: boolean;
  bookkeepingDuplicateDate: boolean;

  public filter_property: ReplaySubject<ImovelDTO[]> = new ReplaySubject<ImovelDTO[]>(1);
  public filter_bankAccount: ReplaySubject<ContaBancariaDTO[]> = new ReplaySubject<ContaBancariaDTO[]>(1);
  public filter_planAccount: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filter_historic: ReplaySubject<HistoricDTOResponse[]> = new ReplaySubject<HistoricDTOResponse[]>(1);

  public historico2: FormControl = new FormControl();
  public propertyFilterCtrl: FormControl = new FormControl();
  public accountPlanFilterCtrl: FormControl = new FormControl();
  public bankAccountFilterCtrl: FormControl = new FormControl();
  public historicFilterCtrl: FormControl = new FormControl();
  public toogleAll = false
  protected _onDestroy = new Subject<void>();

  public locks: DateLock[] = [];

  public groupers: any = [];
  public allGroupers: any[];
  public filteredGroupers: GrouperDTOResponse[];
  public filteredGrouper: Observable<GrouperDTOResponse[]>;
  public grouperCtrl = new FormControl();
  public agrupadores: GrouperDTOResponse[];
  public removable = true;

  public receipts: any = [];
  public allReceipts: any[];
  public filteredReceipts: any[]
  public filteredReceipt: Observable<any[]>;
  public receiptCtrl = new FormControl();

  public loadingHistoric: boolean = false;
  public loadingAccount: boolean = false;

  public separatorKeysCodes: number[] = [ENTER, COMMA];

  public individualHistoricForm;
  public modelHistoricForm;
  public lancamentoSelect = ["selectConta", "selectImovel", "selectContaMovimento", "selectHistorico", "selectDestinatario", "selectDataVencimento", "selectValor", "selectData", "buttonInserir"];
  public lancamentoSelectAux = 0;

  constructor(public activeModal: NgbActiveModal,
              private accountProvider: AccountProvider,
              public historicoUtil: historicoUtil,
              private imovelProvider: PropertyProvider,
              private participantesProvider: ParticipantProvider,
              private contaBancariaProvider: BankAccountProvider,
              private documentProvider: DocumentProvider,
              public consultaPdfXml: ConsultaPdfXml,
              private historicProvider: HistoricProvider,
              public dateUtil: DateUtil,
              public errorUtil: ErrorUtil,
              public dialog: MatDialog,
              public fb: FormBuilder,
              private postingProvider: PostingProvider,
              public snackbar: Snackbar,
              public dfeProvider: DfeProvider,
              public livroCaixa: LivroCaixa,
              public modalService: NgbModal,
              public dateLockProvider: DateLockProvider,
              public checkPermission: CheckPermission,
              public grouperProvider: GrouperProvider,
              public receiptProvider: ReceiptProvider,
              private snackBar: Snackbar,
              public adicionarArquivosModalService: NgbModal,
              private formBuilder: FormBuilder,
              public propertyProvider: PropertyProvider,
              public bankAccountProvider: BankAccountProvider,) {

  }

  ngOnInit() {
    this.initializeParticipant();
    this.initializeDataForm();
    //this.historicoUtil.initializeSearchAutoCompleteHistoric();
   // let teste = new historicoUtil;
    this.configuracaoInicialForm().then((result) => {
      if(result){
        //document.getElementById('selectConta').focus();
      }
    });
    this.setFirstDuplicatesSelected();
    this.calculaValorTotal();
    this.getDateLocks();
    document.addEventListener('keydown', (event) => {this.handleKeyboardEvent(event)});
  }

  handleKeyboardEvent(event){
    if(event.key == 'Escape'){
      this.activeModal.close(this.changeMade);
    }
      // } else if(event.key == 'Enter'){
    //   event.stopImmediatePropagation();
    //   if(document.activeElement.id != "buttonInserir" && document.activeElement.id !="inserirMesmoAssim"){
    //     event.preventDefault();
    //     this.lancamentoSelectAux = this.lancamentoSelect.indexOf(document.activeElement.id.replace('-input', '')) +1;
    //     document.getElementById(this.lancamentoSelect[this.lancamentoSelectAux - 1]).blur();
    //     document.getElementById(this.lancamentoSelect[this.lancamentoSelectAux]).focus();
    //   }
    //   else{
    //     if(document.activeElement.id == "buttonInserir"){
    //       try{
    //         document.getElementById("inserirMesmoAssim").focus();
    //         event.preventDefault();
    //       }
    //       catch{
    //       }
    //     }
    //   }
    // }

  }

  addOnBlur(event: FocusEvent, historicoUtil) {
    const target: HTMLElement = event.relatedTarget as HTMLElement;
    if (!target || target.tagName !== 'MAT-OPTION') {
      const matChipEvent: MatChipInputEvent = {input: this.historicInput.nativeElement, value : this.historicInput.nativeElement.value};
      historicoUtil.addHistoric(matChipEvent);
    }
  }

  selectedHistoric(event, historicoUtil, form): void {
   historicoUtil.historics.push(event.option.viewValue);
    //this.historicInput.nativeElement.value = '';
    historicoUtil.historicCtrl.setValue(null);
    let re = new RegExp('#', "g");

      form.controls.modelo.setValue(form.value.modelo.replace(re,''))

   // form.controls.modelo.setValue(form.value.modelo.replace(new RegExp('#', "g"),''))
  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initializeDataForm() {
    this.imoveis = this.imoveisCache;
    this.categorias = this.categoriasCache;
    this.contas = this.contasCache;
    this.historicos = this.sortHistorics(this.historicosCache);
    this.participantes = this.participantesCache;
    this.initializePropertySearch();
    this.initializeAccountPlanSearch();
    this.initializeBankAccountSearch();
    this.initializeHistoricSearch();
    this.initializeIndividualHistoricForm();


    this.grouperProvider.getAllGroupers().then((groupers: GrouperDTOResponse[]) => {
      this.receiptProvider.getReceiptsByCompanyId().then((receipts: any[]) => {
        this.allGroupers = groupers;
        this.agrupadores = groupers;
        this.filteredGroupers = this.agrupadores;

        this.allReceipts = receipts;
        this.allReceipts.forEach((receipt) =>{
          receipt.formatedDate = moment(receipt.date).locale('pt-BR').format('DD/MM/YYYY')
        })
        this.filteredReceipts = this.allReceipts;
        this.initializeSearchSelect();
      });

    });


  }

  selectedGrouper(event: MatAutocompleteSelectedEvent): void {
    if(event.option.value!=0){
      let eventInsert;
      if (event.option === undefined) {
        eventInsert = event;
      } else {
        eventInsert = event.option.value;
      }

      let exist = false;
      this.groupers.forEach((grouper, index: number) => {
        if (grouper.id === eventInsert.id) {
          exist = true;
          this.snackBar.open('Agrupador já selecionado!', 'atencao');
        }
        if (!exist && this.groupers.length === index + 1) {
          this.groupers.push(eventInsert);
          this.grouperInput.nativeElement.value = '';
          this.grouperCtrl.setValue(null);
        }
      });
      if (this.groupers.length === 0) {
        this.groupers.push(eventInsert);
        this.grouperInput.nativeElement.value = '';
        this.grouperCtrl.setValue(null);
      }
    }
  }

  removeGrouper(grouper, indx): void {
    this.groupers.splice(indx, 1);
  }

  selectedReceipt(event: MatAutocompleteSelectedEvent){
    if(event.option.value!=0){
      let eventInsert;
      if (event.option === undefined) {
        eventInsert = event;
      } else {
        eventInsert = event.option.value;
      }

      let exist = false;
      this.receipts.forEach((receipt, index: number) => {
        if (receipt.id === eventInsert.id) {
          exist = true;
          this.snackBar.open('Arquivo já selecionado!', 'atencao');
        }
        if (!exist && this.receipts.length === index + 1) {
          this.receipts.push(eventInsert);
          this.receiptInput.nativeElement.value = '';
          this.receiptCtrl.setValue(null);
        }
      });
      if (this.receipts.length === 0) {
        this.receipts.push(eventInsert);
        this.receiptInput.nativeElement.value = '';
        this.receiptCtrl.setValue(null);
      }
    }
  }

  initializeParticipant(){
    //console.log("AAAAAAAAA");
    this.documentosExistentes.forEach((element,i) => {

           this.participantesProvider.getParticipantsByCpfCnpj(element.emitente.cpf?
      [element.emitente.cpf]:[element.emitente.cnpj]).then((participantResult) =>{
        //console.log("participant2",participantResult)
        if(participantResult){
        //console.log("participantResult", participantResult)
        let participant = participantResult[0];
        element.emitente.accountId = participant.accountId
        if(participant.accountId){
          this.selectPlanoConta({id: participant.accountId}, true)
          this.selectPlanoContaEachDuplicata({id: participant.accountId}, true, element)
        }
      }
    })


    })


    /**
    this.participantesProvider.getParticipantsByCpfCnpj(this.documentosExistentes[0].emitente.cpf?
      [this.documentosExistentes[0].emitente.cpf]:[this.documentosExistentes[0].emitente.cnpj]).then((participantResult) =>{
        //console.log("participant2",participantResult)
        if(participantResult){
        //console.log("participantResult", participantResult)
        let participant = participantResult[0];
        if(participant.accountId){
          this.selectPlanoConta({id: participant.accountId}, true)
        }
      }
    }) */
}

  changeParticipant(value){
    if(value.checked){
      this.participantesProvider.getParticipantsByCpfCnpj(this.documentosExistentes[0].destinatario.cpf?
        [this.documentosExistentes[0].destinatario.cpf]:[this.documentosExistentes[0].destinatario.cnpj]).then((participantResult) =>{
          //console.log("participant",participantResult)
          if(participantResult){
          let participant = participantResult[0];
          if(participant.accountId){
            this.selectPlanoConta({id: participant.accountId}, true)
          }
        }
      })
    }
  }

  initializeIndividualHistoricForm(){
    this.individualHistoricForm = new FormArray([]);
    this.modelHistoricForm = new FormArray([]);
    this.documentosExistentes.forEach((element, index) => {
    //console.log("OOOOHHH",element)
    element.historicoUtil = new historicoUtil
      this.individualHistoricForm.push(this.formBuilder.group({
        historico: [""],
        modelo: [""],
      }))
    })
    this.setIndividualHistorics('DEFAULT');
  }

  teste($event, provider,i){
   // console.log("holy molly",$event)
    let re = new RegExp('#', "g");
    $event = $event.replace(re,'')
    provider.historics = $event.split(' ')

    //console.log(provider.historics )
    this.syncHistorico()
  }
  setIndividualHistorics(value){

    let model = (value == 'DEFAULT' ? 'LANÇAMENTO #@participante@# DF-e #@numero-documento@#' : value.model)
    //console.log("AAAAA",model)
    this.documentosExistentes.forEach((element,i) => {
   //  console.log("eeeee",element,this.individualHistoricForm.controls[i])
      let re = new RegExp('#', "g");
      this.individualHistoricForm.controls[i].controls.modelo.setValue(model.replace(re,''))
      //element.addControl('modelo', this.fb.control(payment.modelo.replace(re,'')));
      element.historicoUtil.historics = model.split('#');
      element.historicoUtil.historics.forEach((strings, index) => {
        if (strings.trim() === '') {
          element.historicoUtil.historics.splice(index, 1);
        }
      });
      if(element.pagamentoPlanejado){
        element.pagamentoPlanejado.forEach((element2 : any) =>{
          element2.historic = {
            historico: this.convertHistoricModelToString(model,element),
            modelo: model.replace(re,'')
          }
        })
      }
      const objHistoric = {...model};
  //   element.controls.modelo.setValue(objHistoric);

    })
    this.syncHistorico()
  }

syncHistorico(){
  this.individualHistoricForm.controls.forEach((historicForm, index) =>{
      let doc = this.documentosExistentes[index];
      let historic = this.convertHistoricModelToString(doc.historicoUtil.genereteHistoric(), doc)
       //console.log("historic", historic, doc)
 //    console.log("historic trimmed","oh shit", this.modelHistoricForm.controls[index].value.modelo.valueOf(),"aaaa",  this.historicoUtil.genereteHistoric() );

 //historicForm.controls.modelo.setValue(doc.historicoUtil.genereteHistoric());
 historicForm.controls.historico.setValue(historic.trim());
    })
  //console.log("teste123")
}
  convertHistoricModelToString(model, doc){
    //console.log("aaaa23",model)
    let historicSplit = model.split("#");
    let historicString = '';
    historicSplit.forEach((split) => {
      if(split.includes('@')){
        historicString = historicString + ' ' + this.getIndividualHistoricTag(split, doc);
      }
      else{
        historicString = historicString + ' ' + split;
      }
    });
    return historicString;
  }

  getIndividualHistoricTag(tag, doc){
    switch (tag) {
      case '@participante@':
        if (this.saleDfe) {
          return doc.destinatario.nome.toUpperCase();
        } else {
          return doc.emitente.nome.toUpperCase();
        }
      case '@participante-CpfCnpj@':
        if (this.saleDfe) {
          return doc.destinatario.cpf ? doc.destinatario.cpf : doc.destinatario.cnpj;
        } else {
          return doc.emitente.cpf ? doc.emitente.cpf : doc.emitente.cnpj;
        }
      case '@numero-documento@':
        return doc.numeroDoc;
      case '@conta@':
        let contaSplit = this.lancamentoForm.get('categoria').value.description? this.lancamentoForm.get('categoria').value.description.split('-'):'';
        return this.lancamentoForm.get('categoria').value.description ? contaSplit[1] : ' ';
      case '@mes@':
        return moment(this.lancamentoForm.get('dataPagamento').value).locale('pt-br').format('MMM').toUpperCase();
      case '@ano@':
        return moment(this.lancamentoForm.get('dataPagamento').value).format('Y');
      case '@ano-anterior@':
        return moment(this.lancamentoForm.get('dataPagamento').value).subtract(1, 'year').format('Y');
      case '@mes-anterior@':
        return moment(this.lancamentoForm.get('dataPagamento').value).subtract(1, 'month').locale('pt-br').format('MMM').toUpperCase();
      case '@mes-e-ano-anterior@':
        return moment(this.lancamentoForm.get('dataPagamento').value).subtract(1, 'month').locale('pt-br').format('MMM Y').toUpperCase();
      default:
        return tag;
    }
  }

  selectPlanoConta(value, usingParticipant){
    //console.log("value", value,usingParticipant);
    this.loadingAccount = true;
    this.loadingHistoric = true;


    //procura a conta pelo id
    this.accountProvider.getAccountById(value.id).then((contaResult : any) =>{
      //se a conta existir, seta o valor no form, senão encerra
      if(contaResult){
        //console.log("contaaaa>>>",contaResult)
        this.loadingAccount = false;
        this.filter_planAccount.forEach((filter_planAccount) => {
          filter_planAccount.forEach((account) => {
            if(account.id == contaResult.id)
              this.lancamentoForm.controls.categoria.setValue(account);

          })
        });
        //se a conta tiver um historico associado, procura pelo id, senão encerra
        if(contaResult.historicId){
          this.historicProvider.getHistoricById(contaResult.historicId).then((historicResult : any) =>{
            //console.log("hist>>>",contaResult)

            if(historicResult){
              this.setIndividualHistorics(historicResult);
              //se o historico existir, seta o valor no form, senão encerra
              this.filter_historic.forEach((filter_historic) => {
                filter_historic.forEach((historic) => {
                  if(historic.id == historicResult.id)
                    this.lancamentoForm.controls.historico.setValue(historic);
                })
              });
              this.loadingHistoric = false;
              if(usingParticipant){
                //se usou o participante p pegar as infos
                this.snackbar.openLong("Selecionados conta resultado e histórico associado ao participante", 'success');
              }else{
                // se usou a conta p pegar as infos
                this.snackbar.openLong("Selecionado histórico associado a conta resultado", 'success');
              }

            }
            else{
              this.loadingHistoric = false;
            }
          }).catch((error) => {
            this.loadingHistoric = false;
          })
        }
        else{
          if(usingParticipant){
            // se usou o participante p pegar as infos
            this.snackbar.openLong("Selecionada conta resultado associada ao participante", 'success');
          }
          this.loadingHistoric = false;
        }
      }
      else{
        this.loadingAccount = false;
        this.loadingHistoric = false;
      }
    }).catch((error) => {
      this.loadingAccount = false;
      this.loadingHistoric = false;
    });
    this.syncHistorico()
  }

  selectPlanoContaEachDuplicata(value, usingParticipant, element){
    //console.log("value", value,usingParticipant, element);
    this.loadingAccount = true;
    this.loadingHistoric = true;


    //procura a conta pelo id
    this.accountProvider.getAccountById(value.id).then((contaResult : any) =>{
      //se a conta existir, seta o valor no form, senão encerra
      if(contaResult){
        //console.log("contaaaa>>>",contaResult)
        this.loadingAccount = false;
        this.filter_planAccount.forEach((filter_planAccount) => {
          filter_planAccount.forEach((account) => {
            if(account.id == contaResult.id){
              //console.log("RUN THE JWELS",account,element)
              element.pagamentoPlanejado.forEach(element2 =>{
               element2.accountsChart = account
              element2.accountsChartId= account.id
              })

            }
          })
        });
        //se a conta tiver um historico associado, procura pelo id, senão encerra
        if(contaResult.historicId){
          this.historicProvider.getHistoricById(contaResult.historicId).then((historicResult : any) =>{

            //console.log("hist>>>",contaResult)

            if(historicResult){
             // this.setIndividualHistorics(historicResult);
              //se o historico existir, seta o valor no form, senão encerra
              this.filter_historic.forEach((filter_historic) => {
                filter_historic.forEach((historic) => {
                  if(historic.id == historicResult.id){
                    //console.log("historic merder", historic,element)
                    let re = new RegExp('#', "g");

                    element.pagamentoPlanejado.forEach(element2 =>{

                      element2.historic =
                      {
                     historico: this.convertHistoricModelToString(historic.model,element),
                     modelo: historic.model.replace(re,'')
                      }
                     })

                  }

                })
              });
              this.loadingHistoric = false;
              if(usingParticipant){
                //se usou o participante p pegar as infos
                this.snackbar.openLong("Selecionados conta resultado e histórico associado ao participante", 'success');
              }else{
                // se usou a conta p pegar as infos
                this.snackbar.openLong("Selecionado histórico associado a conta resultado", 'success');
              }

            }
            else{
              this.loadingHistoric = false;
            }
          }).catch((error) => {
            this.loadingHistoric = false;
          })
        }
        else{
          if(usingParticipant){
            // se usou o participante p pegar as infos
            this.snackbar.openLong("Selecionada conta resultado associada ao participante", 'success');
          }
          this.loadingHistoric = false;
        }
      }
      else{
        this.loadingAccount = false;
        this.loadingHistoric = false;
      }
    }).catch((error) => {
      this.loadingAccount = false;
      this.loadingHistoric = false;
    });
    this.syncHistorico()
  }


  removeReceipt(indx): void {
    this.receipts.splice(indx, 1);
  }

  getFile(receipt) {
    this.carregando = true;
    //console.log("receipt.file",   receipt.file);
    this.receiptProvider.downloadFile(receipt.file).then((value: any) => {
      const extension = receipt.file.split('.').pop();
      let blob;
      if(extension == 'pdf' || extension == 'xls' || extension == 'xlsx' || extension == 'doc' || extension == 'docx' || extension == 'txt'){
        let type = 'application/' + extension;
        blob = new Blob([value], {type: type});
      }
      else if(extension == 'png' || extension == 'jpg' || extension == 'jpeg'){
        let type = 'image/' + extension;
        blob = new Blob([value], {type: type});
      }
      else{
        blob = new Blob([value], {type: 'application/octet-stream'});
      }
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      this.carregando = false;
    }).catch((error) => {
      this.snackBar.openLong('Erro ao baixar arquivo', 'error');
    });

  }

  openFileModal() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    try {
        const modalRef = this.adicionarArquivosModalService.open(AdicionarArquivosComponent, modalOptions);
        modalRef.result.then((result: any) => {
          if (result !== 'Cancelou') {
            this.receiptProvider.getReceiptsByCompanyId().then((receipts: any[]) => {
              this.allReceipts = receipts;
              this.allReceipts.forEach((receipt) =>{
                receipt.formatedDate = moment(receipt.date).locale('pt-BR').format('DD/MM/YYYY')
              })
              this.filteredReceipts = this.allReceipts;
              this.receiptCtrl.setValue('');
              if(result.lenght != 0){
                result.forEach((receipt) =>{this.receipts.push(receipt)})
              }

            });
          }
        });
    } catch (error) {
        console.error('', error);
    }
  }

  openAgrupadorModal(){
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    try {
        const modalRef = this.modalService.open(CadastroAgrupadoresComponent, modalOptions);
        modalRef.result.then((result: any) => {
          if (result !== 'Cancelou') {
            this.grouperProvider.getAllGroupers().then((groupers: any[]) => {
              this.allGroupers = groupers;
              this.groupers.push(result);
              this.filteredGroupers = this.allGroupers;
              this.grouperCtrl.setValue('');
            });
          }
        });
    } catch (error) {
        console.error('', error);
    }
  }

  openHistoricoIndividualModal(index,element){
    //console.log("elemnt",element)
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    // modalOptions.keyboard =  false;
    try {
        const modalRef = this.modalService.open(HistoricoIndividualComponent, modalOptions);
        modalRef.componentInstance.filter_historic = this.filter_historic
        modalRef.componentInstance.doc = this.documentosExistentes[index]
        modalRef.componentInstance.lancamentoForm = this.lancamentoForm
        modalRef.componentInstance.saleDfe = this.saleDfe
        modalRef.componentInstance.eidtable = element.historic
        modalRef.result.then((result: any) => {
          element.historic = result
          //console.log(result, element)
        })
    } catch (error) {
        console.error('', error);
    }
  }


  sortHistorics(historicos) {
    return historicos.sort((a, b) => {
      if (a.code > b.code) {
        return 1;
      } else if (a.code < b.code) {
        return -1;
      } else {
        return 0;
      }
    })
  }

  initializeAccountPlanSearch() {
    this.filter_planAccount.next(this.categorias.slice());
    this.accountPlanFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterAccountPlan();
      });
  }

  initializePropertySearch() {
    this.filter_property.next(this.imoveis.slice());
    this.propertyFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProperties();
      });
  }

  initializeHistoricSearch() {
    this.filter_historic.next(this.historicos.slice());
    this.historicFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterHistoric();
      });
  }

  initializeBankAccountSearch() {
    this.filter_bankAccount.next(this.contas.slice());
    this.bankAccountFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBankAccount();
      });
  }

  initializeSearchSelect() {

    this.filteredGrouper = this.grouperCtrl.valueChanges.pipe(
      startWith(''),
      map((fruit: string | null) => fruit ? this._filterG(fruit) : this.allGroupers.slice()));

      this.filteredReceipt = this.receiptCtrl.valueChanges.pipe(
        startWith(''),
        map((fruit: string | null) => fruit ? this._filterR(fruit) : this.allReceipts.slice()));

    this.initializeAccountPlanSearch();
    this.initializePropertySearch();
    this.initializeHistoricSearch();
    this.initializeBankAccountSearch();
  }

  private _filterG(value: any): any[] {
    if (typeof value === 'string') {
      const name = value;
      return value ? this.allGroupers.filter(fruit => fruit.name.toLowerCase().includes(name.toLowerCase())) : this.allGroupers;
    }
  }

  private _filterR(value: any): any[] {
    if (typeof value === 'string') {
      const name = value;
      return value ? this.allReceipts.filter(fruit => fruit.description.toLowerCase().includes(name.toLowerCase())) : this.allReceipts;
    }
  }

  convertPropertyObjectSearchSelect(imoveis) {
    let newImovel;
    if (Array.isArray(imoveis)) {
      let newImovelArray: ImovelDTO[] = [];
      newImovel = newImovelArray;
      imoveis.forEach((imovel) => {
        let imovelMod: any = {
          currency: imovel.currency,
          explorationType: imovel.explorationType,
          itrCode: imovel.itrCode,
          name: imovel.propertyCode + ' - ' + imovel.name,
          propertyCode: imovel.propertyCode,
        }
        newImovel.push(imovelMod);
      });
    } else {
      imoveis.name = imoveis.propertyCode + ' - ' + imoveis.name
      newImovel = imoveis;
    }
    return newImovel;
  }

  convertBankAccountObjectSearchSelect(contas) {
    if (Array.isArray(contas)) {
      contas.forEach((contas) => {
        let name = contas.description;
        contas.description = contas.code + ' - ' + name;
      });
    } else {
      let name = contas.description;
      contas.description = contas.code + ' - ' + name;
    }
    return contas;
  }

  convertPlanAccountObjectSearchSelect(planAccounts) {
    let newPlanAccounts: AccountPlanDTOResponse[] = [];
    planAccounts.forEach((categoria) => {
      let categoriaMod: any = {
        id: categoria.id,
        customId: categoria.customId,
        description: categoria.customId + ' - ' + categoria.description,
        featureType: categoria.featureType,
        participateRuralActivity: categoria.participateRuralActivity,
        releaseType: categoria.releaseType
      }
      newPlanAccounts.push(categoriaMod);
    });
    return newPlanAccounts.sort((a, b) => a.customId - b.customId);
  }

  configuracaoInicialForm() {
    this.lancamentoForm = this.fb.group({
      dataPagamento: [moment(this.dateUtil.removeTimeZone(localStorage.getItem('workDate'))).format("YYYY-MM-DD"), Validators.required],
      imovel: [this.imoveis.find(obj => obj.default), Validators.compose([Validators.required])],
      valorTotal: ['0', Validators.compose([ValueValidator, Validators.required])],
      categoria: ['', Validators.compose([ValueValidator, Validators.required])],
      contabancaria: this.livroCaixa.getTipo() == 1 ?
                      [this.contas && this.contas.length > 0 ? this.contas.find(obj => obj.default) : '', Validators.compose([Validators.required])] :
                      [this.contas && this.contas.length > 0 ? this.contas.find(obj => obj.default) : ''],
      historico: ['DEFAULT'],
      lancamentos: [this.selection.selected, Validators.compose([LengthValidator])]
    });
    if (+this.livroCaixa.getTipo() == 1) {
      this.lancamentoForm.controls.contabancaria.setValidators(Validators.compose([Validators.required]));
    }
    moment.locale("pt-br");

    return new Promise ((resolve, reject) => {
      resolve(true);
    })
   // console.log(this.lancamentoForm);

  }

  calculaValorTotal() {
    if (this.selection.selected.length != 0) {
      this.descontoProporcionalAtivo = false;
      let valorTotal: number = 0;
      let idController = []
      this.selection.selected.forEach((dfe: any) => {
           // console.log(dfe)
        if(dfe.escriturada != true && idController.findIndex(val => val === dfe.id) != 0){
          //console.log("teste",idController,idController.findIndex(val => val === dfe.id))
                  valorTotal = +dfe.valor + valorTotal;
                  idController.push(dfe.id)

        }
      });
      this.lancamentoForm.controls.dataPagamento.setValue(moment(this.dateUtil.removeTimeZone(this.selection.selected[0].vencimento)));
    //  console.log(valorTotal)
      this.valorTotal = 0;
      this.valorTotal = valorTotal;
      this.lancamentoForm.controls.valorTotal.setValue(valorTotal);
    } else {
      this.valorTotal = 0;
      this.lancamentoForm.controls.valorTotal.setValue(this.valorTotal);

    }
  }

  calculaValorProporcional() {
    if ((this.lancamentoForm.controls.valorTotal.value / this.valorTotal != 1) && this.selection.selected.length != 0) {
      this.descontoProporcionalAtivo = true;
      this.valorProporcional = (+this.lancamentoForm.controls.valorTotal.value / this.valorTotal).toString();
      // let porcent = (+this.lancamentoForm.controls.valorTotal.value / this.valorTotal);
    } else {
      this.descontoProporcionalAtivo = false;
    }
  }

  isAllSelected() {
    // const numSelected = this.selection.selected.length;
    // const numRows = this.dataSource.data.length;
    // return numSelected === numRows;
   // //console.log("is")
  }
  marcarTodasDeTodas(i,c){
  //console.log(i,c._checked)
  i.forEach(element => {
    element.pagamentoPlanejado.forEach(subElement =>{
      if(c._checked ==false && !this.selection.isSelected(subElement)){
        ////console.log("aaaaaaaaaaaaa")
        subElement.escriturada == false ? this.selection.toggle(subElement) : ""
      }else if(c._checked && this.selection.isSelected(subElement)){
        subElement.escriturada == false ? this.selection.toggle(subElement) : ""

      }
    })
  });
  this.calculaValorTotal()
}
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(documento) {
  // //console.log(documento)
    ////console.log(documento.pagamentoPlanejado)

    if(this.toogleAll == false){
    this.toogleAll = true
    documento.pagamentoPlanejado.forEach(element => {
      if(!this.selection.isSelected(element)){
        ////console.log("aaaaaaaaaaaaa")
              element.escriturada == false ? this.selection.toggle(element) : ""
      }
      });

    }else{
      this.toogleAll = false
     documento.pagamentoPlanejado.forEach(element => {
      if(this.selection.isSelected(element)){
       // //console.log("woqidwioqo")
       element.escriturada == false ? this.selection.toggle(element) : ""
      }
      });
    }
    this.calculaValorTotal();
   // //console.log("master")
     //this.isAllSelected() ?
      // this.selection.clear() :
    // this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ConsultaDfe): string {
    // this.calculaValorTotal();
    if (!row) {
      // return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  returnDataForTable(duplicatas: Duplicatas[]): MatTableDataSource<Duplicatas> {
    duplicatas.sort(function (a, b) {
      return (a.vencimento) < (b.vencimento) ? -1 : (a.vencimento) > (b.vencimento) ? 1 : 0;
    });
    let datasource = new MatTableDataSource(duplicatas);
    return datasource;
  }


  newOptionSelect(type: string) {
    switch (type) {
      case "account":
        this.openModalNewOption(CadastroContaBancariaComponent, this.contas, 'contabancaria');
        break;
      case "property":
        this.openModalNewOption(CadastroImovelComponent, this.imoveis, 'imovel');
        break;
      case "accountPlan":
        this.openModalNewOption(CadastroPlanoDeContasComponent, this.categorias, 'categoria');
        break;
      case "historic":
        this.openModalNewOption(CadastroHistoricoComponent, this.historicos, 'historico');
        break;
      default:
        break;
    }
  }
  openModalContaMovimento(x){
  //console.log("tesssstew",x)
  try {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(ContaMovimentoIndividualComponent, modalOptions);
    modalRef.componentInstance.bankAccount = this.contas;
    if(x.bankAccount){
      modalRef.componentInstance.edit = x.bankAccount
    }
    modalRef.result.then((result) => {
      //this.amounts.push(result);
      //if(result.va)
  //console.log("aaaaaaaaaaaa",result)
   // this.generateTable();
   if(result != "clear"){
      x.bankAccountId = result.id;
      x.bankAccount = result;
   }else{
    x.bankAccountId = null;
    x.bankAccount = null;
   }

   //   this.generateTable();
    }, () => {
      //this.generateTable();
    });
    } catch (error) {

      console.error(error)
    }
  //console.log("oiiii")
  }
  openModalConta(x){
    //console.log("tesssstew",x)
    try {
      let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
      };
      const modalRef = this.modalService.open(ContaIndividualComponent, modalOptions);
      modalRef.componentInstance.planAccount = this.categorias;
      if(x.accountsChartId){
        modalRef.componentInstance.edit = x.accountsChart
      }
      modalRef.result.then((result) => {
        //this.amounts.push(result);
    //console.log("aaaaaaaaaaaa",result)
     // this.generateTable();
     if(result != "clear"){
        x.accountsChartId = result.id;
        x.accountsChart = result;
     }else{
      x.accountsChartId = null;
      x.accountsChart = null;
     }
    //console.log(this.documentosExistentes)
     //   this.generateTable();
      }, () => {
        //this.generateTable();
      });
      } catch (error) {

        console.error(error)
      }
    //console.log("oiiii")
    }
    openModalImovel(x){
      //console.log("tesssstew",x)
      try {
        let modalOptions: NgbModalOptions = {
          backdrop: 'static',
          windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
        };
        const modalRef = this.modalService.open(ImovelIndividualComponent, modalOptions);
       modalRef.componentInstance.imovel = this.imoveis;
        if(x.propertyCode){
          modalRef.componentInstance.edit = x.imovel
        }
        modalRef.result.then((result) => {
          //this.amounts.push(result);
     // console.log("aaaaaaaaaaaa",result)
       // this.generateTable();
       if(result != "clear"){
          x.propertyCode = result.propertyCode;
          x.imovel = result;
       }else{
       x.propertyCode = null;
       x.imovel = null;
       }

       //   this.generateTable();
        }, () => {
          //this.generateTable();
        });
        } catch (error) {

          console.error(error)
        }
      //console.log("oiiii")
      }
  openModalNewOption(modalName, list, filtered) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(modalName, modalOptions);
    modalRef.result.then((result) => {
      if (filtered == 'contabancaria' || filtered == 'historico') {
        list.push(this.convertBankAccountObjectSearchSelect(result));
        this.initializeSearchSelect();
        this.lancamentoForm.get(filtered).setValue(result);
      } else if (filtered == 'imovel') {
        list.push(this.convertPropertyObjectSearchSelect(result));
        this.initializeSearchSelect();
        this.lancamentoForm.get(filtered).setValue(result);
      } else {
        list.push(result);
        this.initializeSearchSelect();
        this.lancamentoForm.get(filtered).setValue(result);
      }
    }, () => {

    });
  }

  inserirLancamento(button) {
    button.disabled = true;
    this.lancamentoForm.controls.lancamentos.setValue(this.selection.selected);
    ////console.log( this.lancamentoForm.controls.lancamentos.value)
    this.enviado = true;

    if(this.isLocked()) {
      Swal.fire({
        title: 'Atenção!',
        text: 'Há lançamentos cadastrados dentro de um bloqueio de período. ' +
                (this.checkPermission.isAccountantAdmin() ?
                'Remova o bloqueio para poder cadastrar um lançamento nesta data.' :
                'Contate um administrador para que o bloqueio seja removido.'),
        icon: 'error'
      }).then((result) => {
        if(result.isConfirmed) {
          button.disabled = false;
        }
      });
    } else {
      if (this.lancamentoForm.valid) {
        this.checkWorkDate(this.lancamentoForm.controls.dataPagamento, button);
      } else {
        button.disabled = false;
        this.snackbar.openLong('Há erros no formulário! Corrija antes de enviar novamente.', 'erro');
      }
    }
  }

  hasHistoric(){
    if(this.historicFilterCtrl.value == null){return true;}
    if('HISTÓRICO PADRÃO FARMCONT'.toLowerCase().includes(this.historicFilterCtrl.value.toLowerCase())){return true;}
    return false;
  }

  editProperty(row: any) {
    this.carregando = true;
    this.propertyProvider.getAllProperty().then((imoveis: any[]) => {
      let property = imoveis.find((imovel) => imovel.propertyCode == row.propertyCode);
      this.carregando = false;
      let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
      };
      const modalRef = this.modalService.open(CadastroImovelComponent, modalOptions);
      modalRef.componentInstance.imovelExistente = property;
      modalRef.result.then((result) => {
        if (result) {
          this.carregando = true;
          this.propertyProvider.getAllProperty().then((imoveis: any[]) => {
            let pipe = new ConvertObjectPipe();
            this.imoveis = pipe.transform(imoveis, 'imoveis');
            this.initializeSearchSelect();
            this.carregando = false;
          }).catch((result: HttpErrorResponse) => {
            this.carregando = false;
          });
        }
      }, () => {
      });
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;
    });
  }


  editContaMovimento(contaMovimento) {
    contaMovimento.description = contaMovimento.description.split(' - ')[1];
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroContaBancariaComponent, modalOptions);
    modalRef.componentInstance.contaExistente = contaMovimento;
    modalRef.result.then((result) => {
      if (result) {
        this.carregando = true;
        this.bankAccountProvider.getAllBanksAccounts().then((contas: any[]) => {
          let pipe = new ConvertObjectPipe();
          this.contas = pipe.transform(contas, 'contasBancarias');
          this.initializeSearchSelect();
          this.carregando = false;
        });
      }
    }, () => {
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;
    });
  }

  editContaResultado(contaResultado){
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroPlanoDeContasComponent, modalOptions);
    // console.log("previous node", node)
    contaResultado.name = contaResultado.description.split(' - ')[1]
    modalRef.componentInstance.node = contaResultado;
    modalRef.componentInstance.editar = true;
    modalRef.componentInstance.root = false;
    modalRef.componentInstance.customIds = [contaResultado.customId];
    modalRef.componentInstance.descriptions = [contaResultado.description.split(' -')[1]];
    modalRef.componentInstance.escrituracao = true;
    modalRef.result.then((result) => {
      if (result) {
        this.carregando = true;
        this.accountProvider.getAllAccountsLeafs().then((categorias: AccountPlanDTOResponse[]) => {
          let pipe = new ConvertObjectPipe();
          this.categorias = pipe.transform(categorias, 'categoria');
          this.initializeAccountPlanSearch()
          this.carregando = false;
        });
      }
    }, () => {
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;
    });
  }

  postNewDocuments(button) {
    let docs = [];
    let docsWillNotBookkepping = [];
    this.documentosExistentes.forEach((dfe: any) => {
      let lancamentos = [];
      let dupEscrituradaID = 0;

      //verefica se o documento possui duplicatas já escrituradas e novas duplicatas a serem inseridas
      this.selection.selected.forEach((dupSelect) => {
        dfe.pagamentoPlanejado.forEach((dup) => {
          if (dup.escriturada) {
            dupEscrituradaID = dup.id;
          }
          if (dup.id == dupSelect.id) {
            lancamentos.push(dupSelect);
          }
        });
      });
      //console.log("AAAAACREEPETER",dfe.destinatario.cnpj)
      //verifica se é nota fiscal de venda
      let pessoaID: any;
      if (this.saleDfe) {
        pessoaID = this.participantes.find(participante => participante.cpfCNPJParticipant === dfe.destinatario.cnpj);
      } else {
        pessoaID = this.participantes.find(participante => participante.cpfCNPJParticipant === dfe.emitente.cnpj);
      }


      let dfePost = {
        lancamentos: lancamentos,
        pessoaID: pessoaID,
        escrituracaoExistenteID: dupEscrituradaID,
        docID: 0,
        paymentID: 0,
        numeroDfe: dfe.numeroDoc
      };
      if(dfe.propertyCode != undefined && dfe.propertyCode != null){
        dfePost['propertyCode'] = dfe.propertyCode
      }
      if (dfePost.lancamentos.length !== 0 && dfePost.pessoaID !== undefined && dfePost.pessoaID !== null && dfePost.pessoaID.cpfCNPJParticipant !== null) {
        docs.push(dfePost);
      } else if(dfePost.lancamentos.length !== 0) {
        docsWillNotBookkepping.push(dfePost);
      }

    });

    if(docs.length !== 0 && docsWillNotBookkepping.length === 0) {
      this.postObject(docs, 0, docs.length, button);
    } else {
      this.docsNotBookkeppingDialog(docs, 0, docs.length, button, docsWillNotBookkepping);
    }
  }

  docsNotBookkeppingDialog(docs, index, docsLenght, button, docsNotBookkepping) {
    const dialogText: Dialog = new Dialog();
    dialogText.button = 'Escriturar e ignorar esses lançamentos';
    dialogText.header = 'Documentos com participantes sem CPF/CNPJ';
    dialogText.buttonCancel = 'Cancelar';
    dialogText.subHeader = 'O participante de ' + docsNotBookkepping.length + ' duplicata(s) selecionada(s) não possui(em) CPF/CNPJ. Cadastre na seção Cadastros > Participantes. ' +
      'Os participantes são: ' + this.generateParticipantsString(docsNotBookkepping);
    const dialogRef = this.dialog.open(BasicAlertComponent, {
      data: dialogText,
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result == dialogText.button) {
        if (docs.length !== 0) {
          this.postObject(docs, 0, docs.length, button);
        } else {
          this.snackbar.openLong('Não há nenhum documento para escriturar!', 'erro');
          button.disabled = false;
        }
      } else {
        button.disabled = false;
      }
    });
  }

  generateParticipantsString(docs) {
    let string = '';
    docs.forEach(doc => {
      string = string + doc.pessoaID.name + ', ';
    });
    return string;
  }

  postObject(doc, index, length, button) {
    index++;
    if (doc[0].escrituracaoExistenteID != 0) {
      this.postLancamentosExistingDocument(doc, index, length, button);
    } else {
      this.postLancamentosNewDocument(doc, index, length, button);
    }
  }

  postLancamentosNewDocument(doc, index, length, button) {
    this.postingProvider.postPostingBatch(this.setObjectToPost(doc[0])).then((any: any) => {
      this.snackbar.openLong('Documento ' + (index) + ' de ' + length + ' escriturado com sucesso!', 'success');
      if (doc.length == 1) {
        this.activeModal.close(any);
      } else {
        doc.splice(0, 1);
        this.individualHistoricForm.controls.shift();
        this.postObject(doc, index, length, button);
      }
    }).catch((result: HttpErrorResponse) => {
      button.disabled = false;
     // //console.log(result);
      if(result.error.error_description.includes('posting_unique_planned_payment_id')) {
        this.snackbar.openLong('Pelo menos uma duplicata das selecionadas já foi inserida.', 'erro');
      } else {
        this.snackbar.openLong('Não foi possível inserir o documento. ' + (index) + '! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bookkeeping'), 'erro');
      }
    });
  }

  postLancamentosExistingDocument(doc, index, length, button) {
    this.postingProvider.getDocAndPaymentIDByDoc(doc[0].escrituracaoExistenteID).then((bookkeeping: any) => {
      doc[0].docID = bookkeeping.docId;
      doc[0].paymentID = bookkeeping.paymentId;
      //console.log(doc)
      if(doc.propertyCode != undefined  && doc.propertyCode != null){
        doc[0].propertyCode = doc.propertyCode
      }

      ////console.log("aaaaaaaaaa",doc)
      this.postingProvider.postPosting(this.setObjectToPost(doc[0]).docs[0].postings).then((any: any) => {
        this.snackbar.openLong('Documento ' + (index) + ' de ' + length + ' escriturado com sucesso!', 'success');
        if (doc.length == 1) {
          this.activeModal.close(any);
        } else {
          doc.splice(0, 1);
          this.individualHistoricForm.controls.shift();
          this.postObject(doc, index, length, button);
        }
      }).catch((result: HttpErrorResponse) => {
        button.disabled = false;
        ////console.log(result);
        if(result.error.error_description.includes('posting_unique_planned_payment_id')) {
          this.snackbar.openLong('Pelo menos uma duplicata das selecionadas já foi inserida.', 'erro');
        } else {
          this.snackbar.openLong('Não foi possível inserir o documento. ' + (index) + '! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bookkeeping'), 'erro');
        }
      });
    });
  }

  setObjectToPost(docSelect) {  //transforma o objeto para envio de Documento com lançamentos em lote
    let formData = this.lancamentoForm.getRawValue();
    let payment = new Payment();
    let doc = new Doc();
    payment.companyId = +localStorage.getItem('idEmpresa');
    payment.proofDoc = null;
    payment.docs = [];
    doc.companyId = payment.companyId;
    doc.participantId = docSelect.pessoaID.id; //vem da dfe
    doc.number = docSelect.numeroDfe;

    if(docSelect.propertyCode != undefined  && docSelect.propertyCode != null){
      doc.propertyCode = docSelect.propertyCode
    }else{
       doc.propertyCode = formData.imovel.propertyCode;
    }
    //console.log("doczada",docSelect)
    doc.docType = "INVOICE";
    // console.log("this.receipts", this.receipts)
    // console.log("doc pre receipts", doc);
    this.receipts.forEach((receipt) => {
      doc.receiptFileIds.push(receipt.id);0
    });
    // console.log("doc pos receipts", doc);
    doc.postings = [];
        //console.log("DOCSLEC",docSelect)

    docSelect.lancamentos.forEach((lancamento) => {
      doc.postings.push(this.convertLancamentosToPostAndPut(lancamento, doc.companyId, formData, docSelect));
    });
    payment.docs.push(doc);
    return payment;
  }

  getAccountPlanDescription(accountPlan: AccountPlanDTOResponse): string {
    return accountPlan.description.replace(accountPlan.customId + ' - ', '');
  }


  checkIfValueExist(value, variable) {
    if (value === undefined || value === null) {
      return '';
    } else {
      switch (variable) {
        case 'name':
          if (value.name !== undefined) {
            return value.name.split(' -')[0];
          } else {
            return '';
          }
        case 'description':
          if (value.description !== undefined) {
            return this.getAccountPlanDescription(value);
          } else {
            return '';
          }
      }
    }
  }

  getHistoricTag(tag, docSelect, documento): string {
    switch (tag) {
      case '@participante@':
        return docSelect.pessoaID.name.toUpperCase();
      case '@participante-CpfCnpj@':
        ////console.log(docSelect)
        if(docSelect.pessoaID && docSelect.pessoaID.cpfCNPJParticipant){
          return docSelect.pessoaID.cpfCNPJParticipant
         }
      case '@numero-documento@':
        return docSelect.numeroDfe;
      case '@conta@':
        return this.checkIfValueExist(documento.categoria, 'description').trim();
      case '@mes@':
        return moment(documento.dataPagamento).locale('pt-br').format('MMM').toUpperCase();
      case '@ano@':
        return moment(documento.dataPagamento).format('Y');
      case '@ano-anterior@':
        return moment(documento.dataPagamento).subtract(1, 'year').format('Y');
      case '@mes-anterior@':
        return moment(documento.dataPagamento).subtract(1, 'month').locale('pt-br').format('MMM').toUpperCase();
      case '@mes-e-ano-anterior@':
        return moment(documento.dataPagamento).subtract(1, 'month').locale('pt-br').format('MMM Y').toUpperCase();
      default:
        return tag;
    }
  }

  generateHistoricModel(docSelectData, documentoData): string {
    if (documentoData.historico === undefined || documentoData.historico === '' || documentoData.historico === 'DEFAULT') {
      return 'LANÇAMENTO ' + docSelectData.pessoaID.name + ' DF-e ' + docSelectData.numeroDfe;
    } else {
      let historic: HistoricDTOResponse = this.historicos.find(model => model.id == documentoData.historico.id);

      let historicSplit = historic.model.split('#');

      let historicNewString = '';
      for (let i = 0; i < historicSplit.length; i++) {
        if (historicSplit[i].includes('@')) {
          historicNewString = historicNewString + ' ' + this.getHistoricTag(historicSplit[i].trim(), docSelectData, documentoData);
        } else {
          historicNewString = historicNewString + ' ' + historicSplit[i].trim();
        }
      }
      return historicNewString.trim();
    }
  }

  convertLancamentosToPostAndPut(lancamento, companyID, formData, docSelect) {  //converte os lançamentos para inserção ou edição
    //console.log("dawmmmm shawtyyyy",lancamento, companyID, formData, docSelect,"oowowwowo", this.individualHistoricForm.controls);

    let posting = new Posting();
    if(!lancamento.historic || lancamento.historic == null){
        posting.historic = JSON.stringify({
          historico: this.individualHistoricForm.controls[0].controls.historico.value,
          modelo: this.individualHistoricForm.controls[0].controls.modelo.value
        }).replace(/\\[tTnN]/g, ' ').normalize('NFD').replace(/([\u0300-\u036f])/g, '');

    }else{
      posting.historic = JSON.stringify({
        historico: lancamento.historic.historico,
        modelo: lancamento.historic.modelo
      }).replace(/\\[tTnN]/g, ' ').normalize('NFD').replace(/([\u0300-\u036f])/g, '');
    }
    //console.log("historic:", posting.historic)

  posting.companyId = companyID;

    if(lancamento.accountsChartId == null || lancamento.accountsChartId == undefined){
      posting.accountsChartId = formData.categoria.id;
  }else{
      posting.accountsChartId = lancamento.accountsChartId;
  }
    //console.log("FUUUUUUUUUUUUUUUUUUUUUUUU",lancamento)
    posting.planPaymentId = lancamento.id;
    posting.groupersIds = [];
    this.groupers.forEach((grouper) => {
      posting.groupersIds.push(grouper.id);
    });
    if (formData.contabancaria !== undefined && formData.contabancaria !== null) {
      //console.log(lancamento.bankAccountId)
      if(lancamento.bankAccountId == null || lancamento.bankAccountId == undefined){
          posting.bankAccountId = formData.contabancaria.id;
      }else{
                  posting.bankAccountId = lancamento.bankAccountId;
      }

    }
    posting.isDigital = (localStorage.getItem('livroCaixa') == '1');
    if (this.descontoProporcionalAtivo) { //que pode ser alterado proporcionalmente
      posting.amountPaid = (+lancamento.valor * +this.valorProporcional).toFixed(2).toString();
    } else {
      posting.amountPaid = lancamento.valor;
    }

    if (docSelect.paymentID != 0) {
      posting.paymentId = docSelect.paymentID;
      posting.docId = docSelect.docID;
    }

    if (this.bookkeepingDuplicateDate) {
      posting.paymentDate = this.dateUtil.removeTimeZone(lancamento.vencimento);
      // posting.paymentDate = moment(lancamento.vencimento).format('YYYY-MM-DDTHH:mm:ss');
    } else {
      posting.paymentDate = this.dateUtil.removeTimeZone(formData.dataPagamento);
    }

    posting.postingReleaseType = ReleaseType[lancamento.accountsChart ? lancamento.accountsChart.releaseType.toString() : formData.categoria.releaseType.toString()];

    return posting;
  }

  editPaymentPlan(pagamentoPlanejado: Duplicatas[], dfeId, dfeIdParcela?,total?) {
    //console.log("duplicata",pagamentoPlanejado,"dfe",dfeId, dfeIdParcela)
    pagamentoPlanejado.forEach((dup) => {
      if (!dup.escriturada) {
        this.selection.deselect(dup);
      }
    });
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(PagamentoPlanejadoComponent, modalOptions);
    modalRef.componentInstance.duplicatesPlanned = pagamentoPlanejado;
    modalRef.componentInstance.total = total
    if(dfeIdParcela){
      //console.log("dfeIdParcela")

      modalRef.componentInstance.dfeId = dfeIdParcela;

    }else{
      //console.log("dfeid")
          modalRef.componentInstance.dfeId = dfeId;

    }
    modalRef.result.then(() => {
      this.toogleAll = false;
      let doc = this.documentosExistentes.find((doc) => doc.id == dfeId);
      doc.pagamentoPlanejado.forEach((dup : any) => {
        if(!dup.historic){
          dup.historic = {
            historico: this.individualHistoricForm.controls[0].controls.historico.value,
            modelo: this.individualHistoricForm.controls[0].controls.modelo.value
          }
        }
      })
      this.masterToggle(doc);
      this.changeMade = true;
      this.calculaValorTotal();
    }, () => {
      this.toogleAll = false;
      let doc = this.documentosExistentes.find((doc) => doc.id == dfeId);
      doc.pagamentoPlanejado.forEach((dup : any) => {
        if(!dup.historic){
          dup.historic = {
            historico: this.individualHistoricForm.controls[0].controls.historico.value,
            modelo: this.individualHistoricForm.controls[0].controls.modelo.value
          }
        }
      })
      this.masterToggle(doc);
      this.changeMade = true;
      this.calculaValorTotal();
    });
  }

  removeDfe(index){
    if(this.documentosExistentes.length == 1){
      Swal.fire({
        title: 'Atenção!',
        text: 'É obrigatório pelo menos um documento.',
        icon: 'warning'
      });
      return;
    }
    Swal.fire({
      title: 'Atenção!',
      text: 'Tem certeza que deseja remover a nota fiscal ' + this.documentosExistentes[index].numeroDoc + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, remover',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      cancelButtonColor: '#d33',
    }).then((result) => {
      if(result.isConfirmed) {
        this.documentosExistentes.splice(index, 1);
        this.individualHistoricForm.removeAt(index);
        this.calculaValorTotal();
      }
    });    
  }

  changeSize(event, size){
    event.target.style.height = size + 'px';
  }

  checkWorkDate(data: any, button) {
    let haveDateOut: boolean;
    let workDate = localStorage.getItem('workDate');
    let startMonth = moment.tz(workDate, 'America/Sao_Paulo').startOf('month').subtract(1, 'day');
    let endMonth = moment.tz(workDate, 'America/Sao_Paulo').endOf('month').add(1, 'day');
    if (moment.tz(moment.tz(data.value, 'America/Sao_Paulo').format("YYYY-MM-DD"), 'America/Sao_Paulo').isBetween((startMonth), (endMonth))) {
    } else {
      haveDateOut = true;
    }
    if (haveDateOut && !this.bookkeepingDuplicateDate) {
      //console.log('workdate', workDate);
      //console.log('startMonth', startMonth);
      //console.log('endMonth', endMonth);
      const dialogRef = this.dialog.open(DataTrabalhoDialog, {disableClose: true});
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result == undefined || result != 'cancelou') {
          this.postNewDocuments(button);
        } else {
          button.disabled = false;
        }
      });
    } else {
      this.postNewDocuments(button);
    }
  }

  protected filterBankAccount() {
    if (!this.contas) {
      return;
    }
    // get the search keyword
    let search = this.bankAccountFilterCtrl.value;
    if (!search) {
      this.filter_bankAccount.next(this.contas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filter_bankAccount.next(
      this.contas.filter(bank => bank.description.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterAccountPlan() {
    if (!this.categorias) {
      return;
    }
    // get the search keyword
    let search = this.accountPlanFilterCtrl.value;
    if (!search) {
      this.filter_planAccount.next(this.categorias.slice());
    } else {
      // filter the planAccount
      search = search.toLowerCase();
      this.filter_planAccount.next(
        this.categorias.filter(value => value.description.toLowerCase().indexOf(search) > -1)
          .sort((a, b) => a.description.indexOf(search) - b.description.indexOf(search))
      );
    }
  }

  protected filterHistoric() {
    if (!this.historicos) {
      return;
    }
    // get the search keyword
    let search = this.historicFilterCtrl.value;
    if (!search) {
      this.filter_historic.next(this.historicos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the properties
    this.filter_historic.next(
      this.historicos.filter(historic => historic.description.toLowerCase().indexOf(search) > -1)
    );
    //console.log("filterHistoric", this.filter_historic);
  }

  protected filterProperties() {
    if (!this.imoveis) {
      return;
    }
    // get the search keyword
    let search = this.propertyFilterCtrl.value;
    if (!search) {
      this.filter_property.next(this.imoveis.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the properties
    this.filter_property.next(
      this.imoveis.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private setFirstDuplicatesSelected() {
    ///HERE
    //console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAA",this.documentosExistentes)
    //this.ordenaSemelhantes()
    this.documentosExistentes.forEach((documento: any) => {
      if(documento.pagamentoPlanejado){
          //
      documento.pagamentoPlanejado.sort(function (a, b) {
        return (a.vencimento) < (b.vencimento) ? -1 : (a.vencimento) > (b.vencimento) ? 1 : 0;
      });
      for (let i = 0; i < documento.pagamentoPlanejado.length; i++) {
              //console.log("sheesh", documento.pagamentoPlanejado[i])
        if (!documento.pagamentoPlanejado[i].escriturada) {
          this.selection.select(documento.pagamentoPlanejado[i]);
          i = documento.pagamentoPlanejado.length;
        }else{
          //console.log("hellow there", documento.pagamentoPlanejado[i].escriturada)
          //checkpoint

        //  this.getHistoricoByPlannedPayment(documento.pagamentoPlanejado[i].id,documento,i )

        }
      }
  }else{
    //console.log("dawn")
    this.ordenaSemelhantes()
    this.masterToggle(documento)
this.setFirstDuplicatesSelected()
  }
      //console.log("DOOOOCSS",documento)

    });



  }

  ordenaSemelhantes(){
            //console.log("shiiitttt",this.documentosExistentes)

    this.documentosExistentes.forEach((y: any, z) => {
              this.documentosExistentes[z].pagamentoPlanejado = []
              this.documentosExistentes[z].valorTotalFormatado = y.valorDFE
              //console.log(this.documentosExistentes[z].valorTotalFormatado)
        this.documentosExistentes.forEach((x: any, i) => {

        if(y.parcelaId == x.parcelaId){
          this.documentosExistentes[z].pagamentoPlanejado.push({
            "numero": x.numberParcela,
            'vencimento': x.dataExpiracao,
            'valor': x.valorTotal,
            'id': x.id,
            'escriturada': x.pago,
          })
          if(x.id != y.id){
             delete this.documentosExistentes[i]
             // delete y[i]
             this.documentosExistentes.length = this.documentosExistentes.length-1
          }
          //console.log("teste",y , x)
        }
      })
    })
    let aux = [];
    this.documentosExistentes.forEach((doc) => aux.push(doc));
    this.documentosExistentes = aux;
    this.setIndividualHistorics('DEFAULT');
    //console.log("shiiitttt2",this.documentosExistentes)

  }
  getDateLocks() {
    this.dateLockProvider.getDateLocks().then((value: DateLock[]) => {
      this.locks = value;
      // ////console.log(value);
    });
  }

  isLocked() {
    // ////console.log(this.lancamentoForm);

    if(this.bookkeepingDuplicateDate) {
      for(let lancamento of this.lancamentoForm.value.lancamentos) {
        for(let lock of this.locks) {
          if(moment(lancamento.vencimento).isBetween(moment(lock.startDate), moment(lock.endDate), undefined, '[]') && lock.lock) {
            return true;
          }
        }
      }
    } else {
      for(let lock of this.locks) {
        if(this.lancamentoForm.value.dataPagamento.isBetween(moment(lock.startDate), moment(lock.endDate), undefined, '[]') && lock.lock) {
          return true;
        }
      }
    }

    return false;
  }
  getHistoricoByPlannedPayment(id, doc, i){
   this.historicProvider.getHistoricByPlannedPayment(id).then( (result: any) => {
    //console.log("AAAAAAA 123 ",result)
 //  let json=  JSON.parse(result)
  //doc.historicoUtil.historics=  result.modelo.split('#');
  //console.log("omaga",doc)
  let re = new RegExp('#', "g");
  this.individualHistoricForm.controls[i].controls.modelo.setValue(result.modelo.replace(re,''))
   // doc.historicoUtil.historics = doc.historicoUtil.checkIfIsEditMode(result.modelo)
   this.syncHistorico()
   // console.log('big city blues',doc.historicoUtil.historics, doc.historicoUtil.genergenereteHistoric() )
   })
  }
  getFileDfe(id, location) {
    this.carregando = true;
    this.dfeProvider.getDfeByID(id).then((dfe: any) => {
      const document = {
        xml: atob(dfe.dfeBytes),
        modelo: dfe.modelType,
        chave: dfe.key
      };

      switch (location) {
        case 'modal':
          this.consultaPdfXml.getPdfModal(document).then((result) => {
            this.carregando = false;
          }).catch((result: HttpErrorResponse) => {
            this.carregando = false;
            console.error(result);
            this.snackbar.openLong('Não foi possível consultar o PDF/XML. ' +
            this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
          });
          break;
        case 'blank':
          this.consultaPdfXml.getPdf(document).then((result) => {
            this.carregando = false;
          }).catch((result: HttpErrorResponse) => {
            this.carregando = false;
            console.error(result);
            this.snackbar.openLong('Não foi possível consultar o PDF/XML. ' +
            this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
          });
          break;
      }
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;
      console.error(result);
      this.snackbar.openLong('Não foi possível consultar o PDF/XML. ' +
      this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
    });
  }
}


class PaymentEdit {
  id: number;
  companyId: number;
  amountPaid: string;
  planPaymentId: number;
  historic: string;
  account: AccountPlanDTOResponse;
  grouper: GrouperDTOResponse[];
  paymentDate: string;
  postingReleaseType: string;
  bankAccount: ContaBancariaDTO;
  doc: DocEdit;
  payment: Payment;
  digital: boolean
}

class Payment {
  id: number;
  companyId: number;
  proofDoc: any;
  docs: Doc[];
}

abstract class DocAbstract {
  id: number;
  companyId: number;
  number: number;
  docType: string;
}

class DocEdit extends DocAbstract {
  property: ImovelDTO;
  participant: ParticipantDTOResponse;
}

class Doc extends DocAbstract {
  propertyCode: string;
  participantId: string;
  postings: Posting[];
  docTypeFormated: string;
  receiptFileIds: any[] = [];
}

class Posting {
  id: number;
  companyId: number;
  amountPaid: string;
  planPaymentId: number;
  historic: any;
  accountsChartId: number;
  paymentDate: string;
  postingReleaseType: string;
  groupersIds: number[];
  bankAccountId: number;
  isDigital: boolean;
  docId: number;
  paymentId: number;
  groupers: any[] = [];
  receipts: any[] = [];
}

export function LengthValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value.length > 0) {
    // return {typeValue: false};
  } else {
    return {typeValue: true};
  }
  return null;

}
