//
// const Painel = {
//     text: 'Painel',
//     link: '/dashboard/v1',
//     icon: 'icon-speedometer'
// };

const Propriedades = {
  text: 'Minhas propriedades',
  link: '/minhas-propriedades',
  icon: 'icon-home'
};

const Backup = {
  text: 'Backup',
  link: '/contador/backup',
  icon: 'fas fa-download'
};

const Estoque = {
  text: 'Estoque',
  link: '/estoque',
  icon: 'fas fa-boxes',
  // alert: 'NOVO!'
};

const DocumentosFiscais = {
  text: 'Monitor Fiscal',
  link: '/monitor-fiscal',
  icon: 'fas fa-barcode',
  parentAsTarget: true,
  submenu: [
    {
      text: 'Download via Chave',
      link: '/monitor-fiscal/chave-acesso',
      icon: 'fas fa-key'
    }
  ]
};

const Conciliacao = {
  text: 'Conciliação',
  link: '/conciliacao-bancaria',
  icon: 'fas fa-university',
  // alert: 'NOVO!'
}

const Financeiro = {
  text: 'Escrituração',
  link: '/escrituracao',
  icon: 'fas fa-money-check-alt',
};

const RelatorioAcessos = {
  text: 'Relatorio de acessos',
  link: '/contador/relatorio-de-acessos',
  icon: 'fas fa-book-open',
};

const LC = {
  text: 'Livro Caixa',
  link: '#',
  icon: 'fas fa-book-open',
  submenu: [
    {
      text: 'Analógico',
      link: '/livro-caixa/lca',
      icon: 'fas fa-scroll'
    },
    {
      text: 'Digital',
      link: '/livro-caixa/lcdpr',
      icon: 'fas fa-book'
    }
  ]
};

const SimuladorIr = {
  text: 'Simulador de IR',
  link: '/simulador-ir',
  icon: 'fas fa-calculator',
  alert: 'NOVO!'
};


const Relatorios = {
  text: 'Relatórios',
  link: '#',
  icon: 'fas fa-chart-area',
  submenu: [
    {
      text: 'Consolidação Mensal',
      link: '/relatorios/consolidacao-mensal',
      icon: 'far fa-chart-bar'
    },
    {
      text: 'Livro Diário',
      link: '/relatorios/livro-caixa',
      icon: 'fas fa-book-open'
    },
    {
      text: 'Livro Razão',
      link: '/relatorios/livro-razao',
      icon: 'fas fa-book'
    },
    {
      text: 'Análise por Participante',
      link: '/relatorios/analise-participante',
      icon: 'fas fa-user-tie',
      // alert: 'NOVO!'
    },
    {
      text: 'Estoque Geral',
      link: '/relatorios/relatorio-estoque',
      icon: 'fas fa-box-open',
      // alert: 'NOVO!'
    },
    {
      text: 'Comparativo',
      link: '/relatorios/comparativo-anual',
      icon: 'fas fa-balance-scale',
      alert: 'NOVO!'
    },
  ]
};

const Cadastros = {
  text: 'Cadastros',
  link: '#',
  icon: 'fas fa-address-book',
  submenu: [
    {
      text: 'Agrupadores',
      link: '/cadastros/agrupadores',
      icon: 'fas fa-tag'
    },
    {
      text: 'Participantes',
      link: '/cadastros/participantes',
      icon: 'fas fa-users'
    },
    {
      text: 'Pastas',
      link: '/cadastros/pastas',
      icon: 'far fa-folder'
    },
    {
      text: 'Plano de contas',
      link: '/cadastros/plano-de-contas',
      icon: 'fas fa-align-left'
    },
    {
      text: 'Históricos',
      link: '/cadastros/historicos',
      icon: 'fas fa-quote-left'
    },
    {
      text: 'Produtos',
      link: '/cadastros/produtos',
      icon: 'fas fa-box',
      // alert: 'NOVO!'
    },
    {
      text: 'Exportações',
      link: '/cadastros/exportacoes',
      icon: 'fas fa-file-export',
      alert: 'NOVO!'
    }
  ]
};

const EnviarArquivos = {
  text: 'Arquivos',
  link: '/enviar-arquivos',
  icon: 'fas fa-file',
  //alert: 'NOVO!'
};

const Cobrancas = {
  text: 'Financeiro',
  link: '/contador/cobrancas',
  icon: 'fas fa-wallet',
  // alert: 'NOVO!'
}

// contador
const Reintegrar = {
  text: 'Reintegrar DF-e',
  link: '/reintegrar',
  icon: 'fas fa-sync-alt'
};

const Empresas = {
  text: 'Empresas',
  link: '/contador/empresas',
  icon: 'fas fa-building'
};

// const Certificados = {
//   text: 'Certificados',
//   link: '/contador/certificados',
//   icon: 'fas fa-certificate'
// };

const Colaboradores = {
  text: 'Colaboradores',
  link: '/contador/colaboradores',
  icon: 'fas fa-users'
};

const MinhaConta = {
  text: 'Minha Conta',
  link: '/contador/minha-conta',
  icon: 'fas fa-user-cog'
};

const NovoCliente = {
  text: 'Novo cliente',
  link: '/contador/novo-cliente',
  icon: 'fas fa-user-plus'
};
const EditarUser = {
  text: 'Editar office',
  link: '/contador/edicao-user',
  icon: 'fas fa-user-plus'
};

const SuperUser = {
  text: 'Superusuário',
  link: '/contador/superuser',
  icon: 'fas fa-user-shield'
};

const RelatoriosGerenciais = {
  text: 'Relatórios Gerenciais',
  link: '#',
  icon: 'fas fa-chart-bar',
  submenu: [
    {
      text: 'Certificados',
      link: '/contador/certificados',
      icon: 'fas fa-certificate'
    },
    {
      text: 'Lançamentos',
      link: '/contador/analise-gerencial',
      icon: 'fas fa-file-invoice-dollar',
      // alert: 'NOVO!'
    },
    {
      text: 'Imóveis',
      link: '/contador/relatorio-empresa',
      icon: 'fas fa-store-alt',
      // alert: 'NOVO!'
    },
    {
      text: 'Produtores',
      link: '/contador/relatorio-produtores',
      icon: 'fas fa-address-card',
      alert: 'NOVO!'
    },
  ]
};

// consultor

const RelatoriosConsultor = {
  text: 'Relatórios',
  link: '#',
  icon: 'fas fa-chart-area',
  submenu: [
    {
      text: 'Consolidação Mensal',
      link: '/consultor/consolidacao-mensal',
      icon: 'far fa-chart-bar'
    },
    {
      text: 'Livro Diário',
      link: '/consultor/livro-caixa',
      icon: 'fas fa-book-open'
    },
    {
      text: 'Livro Razão',
      link: '/consultor/livro-razao',
      icon: 'fas fa-book'
    },
    {
      text: 'Análise por Participante',
      link: '/consultor/analise-participante',
      icon: 'fas fa-user-tie',
      // alert: 'NOVO!'
    },
    {
      text: 'Estoque Geral',
      link: '/relatorios/relatorio-estoque',
      icon: 'fas fa-box-open',
      // alert: 'NOVO!'
    }
  ]
};

const EnviarArquivosConsultor = {
  text: 'Arquivos',
  link: '/enviar-arquivos-consultor',
  icon: 'fas fa-file',
  // alert: 'NOVO!'
}

const Escrituracao = {
  text: 'Escrituração',
  link: '/escrituracao-consultor',
  icon: 'fas fa-money-check-alt',
};

const DocumentosFiscaisConsultor = {
  text: 'Monitor Fiscal',
  link: '/monitor-fiscal-consultor',
  icon: 'fas fa-barcode',
};

const ConciliacaoConsultor = {
  text: 'Conciliação',
  link: '/conciliacao-consultor',
  icon: 'fas fa-university',
  // alert: 'NOVO!'
};

export const menu = [
  // Painel,
  Propriedades,
  DocumentosFiscais,
  Financeiro,
  Conciliacao,
  Estoque,
  Relatorios,
  LC,
  SimuladorIr,
  Cadastros,
  EnviarArquivos,
];

export const menuAnalogico = [
  Propriedades,
  DocumentosFiscais,
  Conciliacao,
  Estoque,
  Financeiro,
  Relatorios,
  Cadastros
];


export const menuContador = [
  Empresas,
  RelatoriosGerenciais,
  Colaboradores,
  MinhaConta,
  Cobrancas, ,
  // Backup
];

export const menuContadorFuncionario = [
  Empresas,
  MinhaConta
  // Certificados
];

export const menuSuper = [
  Empresas,
  RelatoriosGerenciais,
  Colaboradores,
  MinhaConta,
  // Backup,
  LC,
  NovoCliente,
  SuperUser,
  EditarUser,
  Reintegrar,
  Cobrancas,
  RelatorioAcessos,
];

export const menuConsultor = [
  DocumentosFiscaisConsultor,
  Escrituracao,
  ConciliacaoConsultor,
  RelatoriosConsultor,
  EnviarArquivosConsultor,
];
