import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { OAuthService } from "angular-oauth2-oidc";
import { Injectable } from "@angular/core";

@Injectable()
export class ReportsProvider {

  apiReports: string = "/main-records/v1/reports";

  constructor(public http: HttpClient, public oauthService: OAuthService) { }

  getReportMembers(startDate, endDate, params) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiReports}/${localStorage.getItem('idEmpresa')}/participants/${startDate}/${endDate}`, params, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
    });
    // return "ok";
  }

  getReportDairy(startDate, endDate, params) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiReports}/${localStorage.getItem('idEmpresa')}/dairy/${startDate}/${endDate}`, params, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
    });
    // return "ok";
  }

  getReportAnnual(firtsYear, secondYear, params) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiReports}/${localStorage.getItem('idEmpresa')}/annual-comparative/${firtsYear}/${secondYear}`, params, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
    });
  }

  getReportFarmers() {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}${this.apiReports}/all-farmers`,{ headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
    });
  }
}
