import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import 'moment-timezone';
import {FormControl} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {FilterService, ReorderService, SortService, TreeGridComponent} from '@syncfusion/ej2-angular-treegrid';
import {TreeTableConsolidacao} from './treeTableConsolidacao/treeTableConsolidacao';
import {ContaBancariaDTO} from '../../../../model/dto/ContaBancariaDTO';
import {BankAccountProvider} from '../../../../providers/company/bank_account/bank_account';
import {PessoaFiscalDTO} from '../../../../model/dto/PessoaFiscalDTO';
import {MemberProvider} from '../../../../providers/company/member/member';
import {ExcelExportProperties} from '@syncfusion/ej2-grids';
import {PropertyProvider} from '../../../../providers/company/property/property';
import {ImovelDTO} from '../../../../model/dto/ImovelDTO';
import {DateUtil} from '../../../../util/dateUtil';
import {ErrorUtil} from '../../../../util/error';
import {BalanceChart} from "./charts";
import {ReportsUtil} from "../../../../util/Reports";
import {ModalExportComponent} from "../modal-export/modal-export.component";
import {exportIRPFUtil} from "../../../../util/exportIRPF";

@Component({
  selector: 'app-fluxo-de-caixa',
  templateUrl: './consolidacao-mensal.component.html',
  providers: [FilterService, SortService, ReorderService],
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./consolidacao-mensal.component.scss']
})

export class ConsolidacaoMensalComponent implements OnInit {
  @ViewChild('picker', {static: false}) datePicker: MatDatepicker<any>;
  @ViewChild('treegrid', {static: false}) public treeGridObj: TreeGridComponent;
  @ViewChild('treegridCollapse', {static: false}) public treeGridCollapse: TreeGridComponent;
  @ViewChild('grid', {static: false}) public treeMainObj: TreeGridComponent;
  @ViewChild('treegrid2', {static: false}) public treeGridObj2: TreeGridComponent;
  public panelExpanded: boolean = true;
  public pdfSimplificado: boolean = false;
  public pdfSimplificadoColor: boolean = false;
  public bankAccount = new FormControl();
  public property = new FormControl();
  public members = new FormControl();
  public toolbarOptions: any[];
  public years: any;
  public ano: number;
  public deducao: any;
  public data: any[] = [];
  public filterSettings: any;
  public contas: ContaBancariaDTO[];
  public socios: PessoaFiscalDTO[];
  public imoveis: ImovelDTO[];
  public childMapping = 'children';
  public calculoImposto = {
    apuracao: '',
    porcentagem: '',
    result: null,
  };
  public changed: boolean = false;

  private bodyPDF: any[];

  constructor(public modalService: NgbModal,
              private contasProvider: BankAccountProvider,
              private memberProvider: MemberProvider,
              public dateUtil: DateUtil,
              private errorUtil: ErrorUtil,
              public charts: BalanceChart,
              private propertyProvider: PropertyProvider,
              public reportsUtil: ReportsUtil,
              public treeTableConsolidacao: TreeTableConsolidacao) {
  }

  ngOnInit(): void {
    this.defaultConfiguration();
    this.initializeData();
    this.treeTableConsolidacao.start();
    this.charts.start();
    // this.getDashboard();
  }

  initializeData() {
    this.treeTableConsolidacao.consultando = true;
    this.contasProvider.getAllBanksAccounts().then((accounts: ContaBancariaDTO[]) => {
      this.contas = accounts;
      this.memberProvider.getAllMembers().then((members: PessoaFiscalDTO[]) => {
        this.socios = members;
        this.propertyProvider.getAllProperty().then((properties: ImovelDTO[]) => {
        this.imoveis = properties;
          this.initialSelects();
        }).catch(result => {
          this.errorUtil.checkErrorStatus(result, result.status, result.error, 'relatorios-consolidacao');
        });
      });
    });
  }

  actionHandler(args) {
    //console.log(args,args.requestType + ' ' + args.type); //custom Action
   // console.log(args.columnName,args.direction)
   //  this.treeGridObj.sortByColumn(args.columnName,args.direction)
   //  this.treeGridObj2.sortByColumn(args.columnName,args.direction)
}

  defaultConfiguration() {
    this.years = getYears(+moment().format('YYYY') - 20);
    this.ano = +moment(this.dateUtil.removeTimeZone(localStorage.getItem('workDate'))).format('YYYY');
    this.deducao = 0;
    this.toolbarOptions = [
      {text: 'Exportar', tooltipText: 'Exportar',
        prefixIcon: 'e-pdfexport',
        id: 'Export'
      },
    ];
    this.filterSettings = {type: 'Excel'};
  }

  toolbarClick(args: any): void {
    if (args.item.id === 'Export') {
      let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : '',
      };
      const modalRef = this.modalService.open(ModalExportComponent, modalOptions);
      modalRef.componentInstance.relatorio = 'consolidacao-mensal' + (this.treeTableConsolidacao.dataIRPF && this.deducao ? '-irpf' : '');
      modalRef.componentInstance.texto = "as despesas ou receitas de cada conta no ano";
      modalRef.result.then((result: any) => {
        if (result.type === 'pdf') {
            this.exportPDF(result.img);
        } else if (result.type === 'pdf-simplificado') {
            this.exportPDFSimplificado(result.img);
        } else if (result.type === 'dre') {
            this.exportDRE(result.img);
        } else if (result.type === 'irpf'){
          this.exportIRPF();
        } else if (result.type === 'excel') {
            this.exportExcel();
        }
      });
    }
  }

  configExport() {
    this.pdfSimplificado = false;
    this.pdfSimplificadoColor = false;

    const date = moment().format('DD/MM/YY HH:mm').toString();

    let properties = 'Todos os imóveis';
    if (this.property.value != null) {
      if (!(this.property.value.length == this.imoveis.length || this.property.value.length == 0)) {
        let tmp = [];
        this.property.value.forEach((value) => {
          tmp.push(value.propertyCode);
        });
        properties = (tmp.length > 1) ? tmp.join(', ') : tmp[0];
      }
    }

    let accounts = 'Todas as contas';
    if (this.bankAccount.value != null) {
      if (!(this.bankAccount.value.length == this.contas.length || this.bankAccount.value.length == 0)) {
        let tmp = [];
        this.bankAccount.value.forEach((value) => {
          tmp.push(value.description);
        });
        accounts = (tmp.length > 1) ? tmp.join(', ') : tmp[0];
      }
    }

    let members = 'Todos os produtores';
    if (this.members.value != null) {
      if (!(this.members.value.length == this.socios.length || this.members.value.length == 0)) {
        let tmp = [];
        this.members.value.forEach((value) => {
          tmp.push(value.name);
        });
        if (tmp.length > 1) {
          members = tmp.join(', ');
        } else if (tmp.length == 1) {
          members = `${this.members.value[0].name} (${this.members.value[0].cpfCNPJ})`;
        }
      }
    }

    let deducao: string;
    if (this.deducao === 0) {
      deducao = 'Todos os valores';
    } else if (this.deducao === true) {
      deducao = 'Dedutíveis';
    } else {
      deducao = 'Não dedutíveis';
    }

    this.bodyPDF = [];
    if (this.treeTableConsolidacao.data.length > 0) this.processBodyPDF(this.treeTableConsolidacao.data,this.childMapping == 'children');

    return {date, properties, accounts, members, deducao};
  }

  exportPDF(img: boolean){
    let params = this.configExport();

    let headers = [
      {dataKey: 'conta',     header: 'Conta'},
      {dataKey: 'janeiro',   header: 'Jan'},
      {dataKey: 'fevereiro', header: 'Fev'},
      {dataKey: 'marco',     header: 'Mar'},
      {dataKey: 'abril',     header: 'Abr'},
      {dataKey: 'maio',      header: 'Mai'},
      {dataKey: 'junho',     header: 'Jun'},
      {dataKey: 'julho',     header: 'Jul'},
      {dataKey: 'agosto',    header: 'Ago'},
      {dataKey: 'setembro',  header: 'Set'},
      {dataKey: 'outubro',   header: 'Out'},
      {dataKey: 'novembro',  header: 'Nov'},
      {dataKey: 'dezembro',  header: 'Dez'},
      {dataKey: 'total',     header: 'Total(R$)'}
    ];

    let colunas = [
      {dataKey: 'janeiro',   minCellWidth: 1.5, halign: 'center'},
      {dataKey: 'fevereiro', minCellWidth: 1.5, halign: 'center'},
      {dataKey: 'marco',     minCellWidth: 1.5, halign: 'center'},
      {dataKey: 'abril',     minCellWidth: 1.5, halign: 'center'},
      {dataKey: 'maio',      minCellWidth: 1.5, halign: 'center'},
      {dataKey: 'junho',     minCellWidth: 1.5, halign: 'center'},
      {dataKey: 'julho',     minCellWidth: 1.5, halign: 'center'},
      {dataKey: 'agosto',    minCellWidth: 1.5, halign: 'center'},
      {dataKey: 'setembro',  minCellWidth: 1.5, halign: 'center'},
      {dataKey: 'outubro',   minCellWidth: 1.5, halign: 'center'},
      {dataKey: 'novembro',  minCellWidth: 1.5, halign: 'center'},
      {dataKey: 'dezembro',  minCellWidth: 1.5, halign: 'center'},
      {dataKey: 'total',     halign: 'center'},
      {dataKey: 'conta',     cellWidth: 2.5},
    ];

    this.reportsUtil.exportPDF({
      doc: {
        arquivo: `Consolidacao mensal - ${this.ano}`,
        orientacao: 'Landscape',
      },
      cabecalho: {
        titulo: `Consolidação Mensal`,
        data: params.date,
        periodo: this.ano.toString(),
        parametros: {
          "Dedução": params.deducao,
          "Imóveis": params.properties,
          "Produtores": params.members,
          "Contas": params.accounts,
        },
        image: img,
      },
      tabela: {
        dados: this.bodyPDF,
        colunas: headers,
        diminuir: true,
        estiloColunas: colunas,
        hierarquico: true
      },
    });
  }

  exportExcel(){
    const exportProperties: ExcelExportProperties = {
      includeHiddenColumn: true,
      fileName: 'consolidacaomensal' + this.ano + '.xlsx',
    };
    if (this.childMapping == 'children') {
      this.treeGridObj.excelExport(exportProperties);
    } else {
      this.treeGridCollapse.excelExport(exportProperties);
    }
  }

  exportPDFSimplificado(img: boolean){
    let params = this.configExport();

    let headers = [
      {dataKey: 'conta',     header: 'Mês'},
      {dataKey: 'receita',   header: 'Receita'},
      {dataKey: 'despesa',   header: 'Despesa'},
      {dataKey: 'resultado', header: 'Total (R$)'},
      {dataKey: 'acumulado', header: 'Total acumulado (R$)'}
    ];

    let colunas = [
      {dataKey: 'despesa',   halign: 'center'},
      {dataKey: 'receita',   halign: 'center'},
      {dataKey: 'resultado', halign: 'center'},
      {dataKey: 'acumulado', halign: 'center'},
    ];

    let tmp = [];
    let acumulado = 0;
    this.treeTableConsolidacao.dataSimp.map((item, i) => {
      if (i < 12) acumulado += parseFloat(item.resultado.replaceAll(".", "").replace(',', '.'));
      let txt = Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(acumulado);
      tmp.push({
        conta: item.nome,
        despesa: Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.despesa.replaceAll(".", "").replace(',', '.')),
        receita: Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.receita.replaceAll(".", "").replace(',', '.')),
        resultado: Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.resultado.replaceAll(".", "").replace(',', '.')),
        acumulado: item.nome.includes('Total') ? null : txt,
      });
    });

    this.reportsUtil.exportPDF({
      doc: {
        arquivo: `Consolidacao mensal simplificada - ${this.ano}`,
        orientacao: 'Landscape',
      },
      cabecalho: {
        titulo: `Consolidação Mensal Simplificada`,
        data: params.date,
        periodo: this.ano.toString(),
        parametros: {
          "Dedução": params.deducao,
          "Imóveis": params.properties,
          "Produtores": params.members,
          "Contas": params.accounts,
        },
        image: img,
      },
      tabela: {
        dados: tmp,
        colunas: headers,
        estiloColunas: colunas,
      },
    });
  }

  exportDRE(img: boolean){
    let params = this.configExport();

    let headers = [
      {dataKey: 'conta',     header: 'Conta'},
      {dataKey: 'total',     header: 'Total(R$)'}
    ];

    let colunas = [
      {dataKey: 'total', halign: 'center'},
    ];

    this.bodyPDF.pop();

    this.reportsUtil.exportPDF({
      doc: {
        arquivo: `Consolidacao mensal - ${this.ano}`,
        orientacao: 'Portrait',
      },
      cabecalho: {
        titulo: `Consolidação Mensal`,
        data: params.date,
        periodo: this.ano.toString(),
        parametros: {
          "Dedução": params.deducao,
          "Imóveis": params.properties,
          "Produtores": params.members,
          "Contas": params.accounts,
        },
        image: img,
      },
      tabela: {
        dados: this.bodyPDF,
        colunas: headers,
        estiloColunas: colunas,
        hierarquico: true
      },
    });
  }

  exportIRPF() {
    let filename = `${this.members.value[0].cpfCNPJ}-ARURAL-${this.ano}-${this.ano}-EXPORTA-IRPF${this.ano+1}`;
    exportIRPFUtil.exportFileIRPF(this.treeTableConsolidacao.dataIRPF, filename);
  }

  // exportGraficos(){
  //   this.charts.loading = true;
  //   this.charts.expanded = false;
  //   this.charts.isForExport = true;
  //   let params = this.configExport();
  //
  //   let graficos = [];
  //   this.tabs('Receita');
  //   let canvas = document.getElementById("myChart0") as HTMLCanvasElement;
  //   let canvas2 = document.getElementById("myChart1") as HTMLCanvasElement;
  //
  //   setTimeout(() => {
  //     graficos.push({
  //       titulo: 'Análise de Receitas',
  //       imagem: canvas.toDataURL(),
  //       altura: canvas.height,
  //       largura: canvas.width,
  //     },{
  //       titulo: '',
  //       imagem: canvas2.toDataURL(),
  //       altura: canvas2.height,
  //       largura: canvas2.width,
  //     });
  //     this.tabs('Despesa');
  //     let canvas3 = document.getElementById("myChart0") as HTMLCanvasElement;
  //     let canvas4 = document.getElementById("myChart1") as HTMLCanvasElement;
  //     setTimeout(() => {
  //       graficos.push({
  //         titulo: 'Análise de Despesas',
  //         imagem: canvas3.toDataURL(),
  //         altura: canvas3.height,
  //         largura: canvas3.width,
  //       },{
  //         titulo: '',
  //         imagem: canvas4.toDataURL(),
  //         altura: canvas4.height,
  //         largura: canvas4.width,
  //       });
  //
  //       this.charts.isForExport = false;
  //       this.tabs(this.activeLink);
  //
  //       this.reportsUtil.exportPDFGraph({
  //         doc: {
  //           arquivo: `Consolidacao mensal - Graficos - ${this.ano}`,
  //           orientacao: 'Portrait',
  //         },
  //         cabecalho: {
  //           titulo: `Consolidação Mensal`,
  //           data: params.date,
  //           periodo: this.ano.toString(),
  //           parametros: {
  //             "Dedução": params.deducao,
  //             "Imóveis": params.properties,
  //             "Produtores": params.members,
  //             "Contas": params.accounts,
  //           },
  //           image: false,
  //         },
  //         graficos: graficos,
  //       });
  //       this.charts.expanded = true;
  //       this.charts.loading = false;
  //     }, 1000);
  //   }, 1000);
  // }

  processBodyPDF(data, children, depth = 0) {
    data.forEach((item) => {
      let tmp = {
        conta: "  ".repeat(depth * 2) + item.conta,
        janeiro: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.janeiro.replaceAll(".", "").replace(',', '.')),
        fevereiro: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.fevereiro.replaceAll(".", "").replace(',', '.')),
        marco: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.marco.replaceAll(".", "").replace(',', '.')),
        abril: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.abril.replaceAll(".", "").replace(',', '.')),
        maio: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.maio.replaceAll(".", "").replace(',', '.')),
        junho: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.junho.replaceAll(".", "").replace(',', '.')),
        julho: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.julho.replaceAll(".", "").replace(',', '.')),
        agosto: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.agosto.replaceAll(".", "").replace(',', '.')),
        setembro: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.setembro.replaceAll(".", "").replace(',', '.')),
        outubro: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.outubro.replaceAll(".", "").replace(',', '.')),
        novembro: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.novembro.replaceAll(".", "").replace(',', '.')),
        dezembro: Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.dezembro.replaceAll(".", "").replace(',', '.')),
        total: item.total != '' ? Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.total.replaceAll(".", "").replace(',', '.')) : '',
        depth: depth
      };

      this.bodyPDF.push(tmp);

      if (item.children.length > 0 && children) {
        this.processBodyPDF(item.children,true,depth + 1);
      }
    });
  }

  amountIsPositive(value): boolean {
    let valueOriginal = (value.replace(/[.]+/g, '')).replace(',', '.');
    return +valueOriginal >= 0;
  }

  updateChanged() {
    this.changed = true;
  }

  chosenYearHandler(normalizedYear: any) {
    this.ano = normalizedYear;
  }

  tabsTypeFilter(c?) {
    this.changed = false;
    let members;   // se não existir um produtor selecionado, consulta com todos.
    if (this.members.value == null || this.members.value.length == 0) {
      members = [];
    } else {
      members = this.members.value.map((value) => value.cpfCNPJ);
    }

    let properties;   // se não existir um imovel selecionado, consulta com todos.
    if (this.property.value == null || this.property.value.length == 0) {
      properties = [];
    } else {
      properties = this.property.value.map((value) => value.propertyCode);
    }

    let bankAccount;
    if (this.bankAccount.value == null || this.bankAccount.value.length == 0) {
      bankAccount = [];
    } else {
      bankAccount = this.bankAccount.value.map((value) => value.id);
    }

    let deducao
    if (this.deducao === 0) {
      deducao = null
    } else {
      deducao = this.deducao
    }
    this.treeTableConsolidacao.getLancamentos(this.ano.toString(), bankAccount, properties, members, deducao);
  }

  limpaMembers(bool) {
    if (bool) {
      this.members.setValue(this.socios);
    } else {
      this.members.setValue([])
    }
  }

  limpaImoveis(bool) {
    if (bool) {
      this.property.setValue(this.imoveis)
    } else {
      this.property.setValue([])
    }
  }

  limpaConta(bool) {
    if (bool) {
      this.bankAccount.setValue(this.contas)
    } else {
      this.bankAccount.setValue([])
    }
  }

  initialSelects() {
    this.members.setValue([])
    this.property.setValue([])
    this.bankAccount.setValue([])
    this.treeTableConsolidacao.start();
  }

  collapseTable() {
    if (this.childMapping != 'children') {
      this.childMapping = 'children';
      this.treeMainObj.expandAtLevel(0);
    } else {
      this.childMapping = null;
      this.treeMainObj.collapseAtLevel(0);
    }
  }

}

function getYears(startYear) {
  const currentYear = new Date().getFullYear() + 1, years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}
